import cx from 'classnames';

import styles from './ShakeDiv.scss';

interface ShakeDivProps {
  children?: React.ReactNode;
  /** If equal to `error`, shake the div. */
  animate: 'normal' | 'error';
  className?: string;
}

const ShakeDiv = ({ children, animate, className }: ShakeDivProps) => (
  <div className={cx(className, { [styles.error]: animate === 'error' })}>{children}</div>
);
export default ShakeDiv;
