import { useMutation } from '@apollo/client';
import { differenceWith, isEqual, omit } from 'lodash';
import { useCallback } from 'react';

import { FiltersDocument, type FiltersType, MutateFiltersDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

export default function ({
  userId,
  botId,
  filters,
  type,
  path,
}: {
  userId: string;
  botId: string;
  filters: any;
  type: 'create' | 'update';
  path: string;
}) {
  const [updateFilter] = useMutation(MutateFiltersDocument);
  const toast = useToast();

  const onSubmit = useCallback(
    async (
      {
        name,
        isFavorite,
        isWorkspace,
        filters: updatedFilters,
      }: {
        name?: string;
        isFavorite?: boolean;
        isWorkspace?: boolean;
        filters: FiltersType['filters'] | FiltersType[];
      },
      _, // form
      onCallback?: (val: FiltersType[]) => void,
    ) => {
      const payload = {} as { create: any; update: any; delete: any };

      if (type === 'create') {
        payload.create = [
          {
            filters,
            name,
            isFavorite,
            isWorkspace,
            path,
          },
        ];
      } else if (type === 'update') {
        // Filter out the deleted filters and remove the index field
        // The index field is for the search, since we need stable indexes
        // We don't need to send it to the backend
        const filteredUpdatedFilters: FiltersType[] = updatedFilters
          ?.filter((filter) => !filter.deleted)
          .map((filter) => omit(filter, ['index']));

        // Get the difference between the existing filters and the updated ones
        const updated = differenceWith(filteredUpdatedFilters, filters, isEqual)?.map((filter) =>
          omit(filter, ['updatedAt', 'user', 'deleted', '__typename']),
        );

        if (updated?.length) {
          payload.update = updated;
        }

        // We mark the filters as deleted if the user has clicked on remove, we don't remove them directly from the state
        const deleted = updatedFilters.filter(({ deleted: isDeleted }) => isDeleted)?.map(({ id }) => id);
        if (deleted?.length) {
          payload.delete = deleted;
        }
      }

      await updateFilter({
        variables: {
          botId: botId!,
          ...payload,
        },
        update(cache, { data }) {
          if (!data?.filtersList) return;

          // Read the current filters from the cache
          const existingFilters = cache.readQuery<{ filtersList: FiltersType[] }>({
            query: FiltersDocument,
            variables: { userId, botId, path },
          });

          // Prepare the new filters list
          const updatedFiltersList = existingFilters?.filtersList
            ? [
                ...existingFilters.filtersList.filter(
                  (existing) => !data.filtersList?.some((updated) => updated.id === existing.id),
                ),
                ...data.filtersList,
              ]
            : data.filtersList;

          // Write the updated filters back to the cache
          cache.writeQuery({
            query: FiltersDocument,
            variables: { userId, botId, path },
            data: { filtersList: updatedFiltersList },
          });
        },
        onCompleted: (val) => {
          if (val.filtersList?.length && onCallback) {
            onCallback(val.filtersList as FiltersType[]);
          }
        },
      });

      toast.success(name ? `Saved filter ${name}` : 'Filters updated.');
    },
    [botId, userId, updateFilter, filters, toast, type, path],
  );

  return onSubmit;
}
