import cx from 'classnames';

import type { ButtonSliderDetailsType } from 'frontend/api/generated';
import { CheckboxOnly } from 'frontend/components';
import sanitizeHTML from 'frontend/utils/sanitizeHTML';

import styles from './ChatBubble.scss';
import { SLIDER_AFFIX_TYPES } from '../../features/Build/constants';

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
}

const Button = ({ children, className }: ButtonProps) => {
  const classNames = cx(styles.button, className);

  return <div className={classNames}>{children}</div>;
};

interface CheckboxButtonProps {
  children?: React.ReactNode;
  className?: string;
}

const CheckboxButton = ({ children, className }: CheckboxButtonProps) => {
  const classNames = cx(styles.checkboxButton, className);

  return (
    <div className={classNames} translate="no">
      <span className={styles.checkIcon}>
        <CheckboxOnly checked readOnly name="checkbox" />
      </span>
      <div>{children}</div>
    </div>
  );
};

interface ChatBubbleSliderProps {
  name?: string;
  sliderDetails: ButtonSliderDetailsType;
}

const ChatBubbleSlider = ({
  name,
  sliderDetails: { fromValue, toValue, value, affix, affixValue },
}: ChatBubbleSliderProps) => (
  <div className={styles.chatBubbleSliderWrapper}>
    {!!affix && (
      <div className={styles.chatBubbleSliderAffixWrapper}>
        {affix === SLIDER_AFFIX_TYPES.PREFIX && <div className={styles.chatBubbleSliderAffixValue}>{affixValue}</div>}
        <span className={styles.chatBubbleSliderValue}>{value}</span>
        {affix === SLIDER_AFFIX_TYPES.SUFFIX && <div className={styles.chatBubbleSliderAffixValue}>{affixValue}</div>}
      </div>
    )}
    <input type="range" min={fromValue} max={toValue} value={value} name={name} disabled readOnly />
  </div>
);

interface ChatBubbleProps {
  children?: React.ReactNode;
  className?: string;
  hasImage?: boolean;
  isFromUser?: boolean;
  isFromAgent?: boolean;
  text?: string | React.ReactNode;
}

const ChatBubble = ({ children, className, hasImage, isFromUser, isFromAgent, text }: ChatBubbleProps) => {
  const classNames = cx(styles.bubble, className, {
    [styles.hasImage]: hasImage,
    [styles.bubbleFromBot]: !isFromUser,
    [styles.bubbleFromUser]: isFromUser,
    [styles.bubbleFromAgent]: isFromAgent,
  });

  return (
    <div className={classNames}>
      <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} />
      {children}
    </div>
  );
};

ChatBubble.Button = Button;
ChatBubble.CheckboxButton = CheckboxButton;
ChatBubble.Slider = ChatBubbleSlider;

export default ChatBubble;
