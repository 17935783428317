import { useMemo } from 'react';

import Label, { type LabelEditProps, type LabelNonEditProps } from 'frontend/features/Labels/Label/Label';
import type { Label as LabelType } from 'frontend/propTypes/LabelType';

import styles from './LabelList.scss';
import SystemLabels from './SystemLabels';

interface CommonProps {
  activeLabelsIds: string[];
  totalLabelsLength: number;
  className?: string;
  handleLabelToggle: (label: LabelType, status: boolean) => void;
  searchedLabels: LabelType[];
  shouldDisplaySystemLabels?: boolean;
}

type Props = CommonProps & (LabelEditProps | LabelNonEditProps);

export default function LabelList({
  totalLabelsLength,
  canEditLabels,
  handleLabelToggle,
  searchedLabels,
  setLabelManagerPhase,
  setLabelToManage,
  setInput,
  activeLabelsIds,
  className,
  inputRef,
  shouldDisplaySystemLabels,
}: Props) {
  // Update label active state according to the FormState
  const updatedSearchedLabels = useMemo(
    () =>
      searchedLabels.map((label) => ({
        ...label,
        active: activeLabelsIds.includes(label.id),
      })),
    [activeLabelsIds, searchedLabels],
  );

  // Sort labels by active state
  const sortedByActiveLabels = useMemo(
    () =>
      updatedSearchedLabels.sort((a, b) => {
        if (a.active && !b.active) {
          return -1;
        }
        if (!a.active && b.active) {
          return 1;
        }
        return 0;
      }),
    [updatedSearchedLabels],
  );

  const defaultTypeLabels = sortedByActiveLabels?.filter((label) => label.type === 'DEFAULT');
  const agentRequiredTypeLabels = sortedByActiveLabels?.filter((label) => label.type === 'AGENT_REQUIRED');

  return (
    <div className={className}>
      <ul className={styles.list}>
        {defaultTypeLabels.map((searchedLabel) => (
          <Label
            key={searchedLabel.id}
            label={searchedLabel}
            isActive={searchedLabel.active}
            handleToggle={handleLabelToggle}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(canEditLabels
              ? { canEditLabels, setLabelManagerPhase, setLabelToManage, setInput, inputRef }
              : { canEditLabels })}
          />
        ))}
        {shouldDisplaySystemLabels && agentRequiredTypeLabels.length > 0 && (
          <SystemLabels>
            <ul className={styles.list}>
              {agentRequiredTypeLabels.map((label) => (
                <Label
                  key={label.id}
                  label={label}
                  isActive={label.active}
                  handleToggle={handleLabelToggle}
                  canEditLabels={false}
                  shape="square"
                />
              ))}
            </ul>
          </SystemLabels>
        )}
      </ul>
      {searchedLabels.length !== totalLabelsLength && (
        <p className={styles.showText}>{`Showing ${searchedLabels.length} of ${totalLabelsLength} labels`}</p>
      )}
    </div>
  );
}
