import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { LoaderSwitch, PageBar, Panel, Range, ToggleSwitch } from 'frontend/components';
import { useBotSettings, useGeneralForm } from 'frontend/features/BotSettings/hooks';
import HelpCenterLink from 'frontend/features/HelpCenter/HelpCenterLink';
import type { BotLanguage } from 'frontend/types/language';

import styles from '../../../General/styles.scss';

const NLPThresholdWarning = () => (
  <div className={styles.thresholdWarning}>
    Setting a very low threshold will result in a higher chance of the bot answering with an incorrect dialogue instead
    of going to fallback. <br /> Setting a very high threshold will result in more fallbacks.
  </div>
);

const THRESHOLD_WARNINGS = {
  BELOW: 0.5,
  ABOVE: 0.8,
};

export default function NLPAndLM() {
  const { onSubmit, initialValues, loading: formDataLoading } = useGeneralForm();
  const loading = formDataLoading;
  const languages = (initialValues.bot?.languages ?? []) as BotLanguage[];

  const { botId } = useParams();
  const { botSettings } = useBotSettings(botId);
  const isIntentBot = botSettings?.intentMatchEnabled;

  return (
    <LoaderSwitch loading={loading} size="large">
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <Panel>
                {!isIntentBot && (
                  <>
                    <h3 className={`${styles.sectionTitle} m-b-3`}>
                      Natural Language Processing &amp; Machine Learning
                    </h3>
                    <p>
                      The confidence score is a percentage value between 0 and 100. The bot becomes stricter with a
                      higher threshold, giving it more confidence in its replies.
                    </p>
                    <p>If the confidence score is less than the threshold value, a fallback will be triggered.</p>
                    <br />
                    {languages.map(({ name, code }) => (
                      <label key={`language-${code}`} className={styles.label}>
                        Confidence threshold for {name}
                        <div className={`${styles.inputContainer} m-t-sm m-b-md`}>
                          <Range
                            name={`bot.nlpSettings.confidenceThresholds.${code}`}
                            min={0}
                            max={1}
                            step={0.01}
                            displayValue={(value) => value * 100}
                            unit="%"
                            displayFixedDecimals={0}
                          />
                        </div>
                        {(initialValues.bot?.nlpSettings?.confidenceThresholds[code] < THRESHOLD_WARNINGS.BELOW ||
                          initialValues.bot?.nlpSettings?.confidenceThresholds[code] > THRESHOLD_WARNINGS.ABOVE) && (
                          <NLPThresholdWarning />
                        )}
                      </label>
                    ))}
                    <Panel.Separator />
                  </>
                )}

                <h3 className={`${styles.sectionTitle} m-b-3`}>Suggestions</h3>
                <p>
                  Using Suggestions, your bot may offer several answers or an answer it is less sure of. Note that this
                  is only available in Kindly Chat.
                  <br />
                  <br />
                  <HelpCenterLink path="/languages#IRod5">Read more about suggestions here</HelpCenterLink>.
                </p>
                <br />
                <ToggleSwitch
                  name="bot.suggestionsEnabled"
                  className="m-r-md"
                  status={['Suggestions enabled', 'Suggestions disabled']}
                />
              </Panel>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
}
