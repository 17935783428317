import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextNode } from 'lexical';
import { useEffect } from 'react';

const REGEX_BULLET_LIST = /^-\s/;
const REGEX_ORDERED_LIST = /^\d+\.\s/;

/**
 * Automatically insert list nodes when the user starts typing a list with "- " or a numbered list with "<number>. " on a new line.
 */
export default function AutoListPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerNodeTransform(TextNode, (node) => {
        if (node.getPreviousSibling() !== null) return;

        const text = node.getTextContent();
        const bulletMatch = text.match(REGEX_BULLET_LIST);
        if (bulletMatch) {
          node.setTextContent(text.slice(bulletMatch[0].length));
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          return;
        }

        const orderedMatch = text.match(REGEX_ORDERED_LIST);
        if (orderedMatch) {
          node.setTextContent(text.slice(orderedMatch[0].length));
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }
      }),
    [editor],
  );

  return null;
}
