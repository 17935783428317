import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Panel } from 'frontend/components';
import { isSystemDialoguePath } from 'frontend/features/Library/utils/breadcrumbs';
import { useEventListener } from 'frontend/hooks';
import { selectLibrarySearch } from 'frontend/state/dux/library';

import styles from './Library.scss';
import { LibraryHeader, LibrarySearch } from './components';
import { useBreadcrumbs, useResize } from './hooks';
import { BotImprovementsView, DialogueView, NavigationView, ProactivityView, SystemView } from './views';
import { LibraryViewNavEnum } from './views/NavigationView/NavigationView';

const DIALOGUE_PATHS = ['dialogue', 'greeting', 'fallback'];

const Library = () => {
  const { pathname } = useLocation();
  const [hasScrolled, setHasScrolled] = useState(false);
  const dispatch = useDispatch();

  const libraryNavigationRef = useRef<HTMLDivElement>(null);

  const { isOpen: isSearchOpen } = useSelector(selectLibrarySearch);
  const [currentView, setCurrentView] = useState<keyof typeof LibraryViewNavEnum>(LibraryViewNavEnum.NAVIGATION);
  const { setPanelRef, onMouseDown } = useResize();

  const { isInBreadcrumbs: inSystemDialogues } = useBreadcrumbs({
    autoOpen: false,
    checkBreadcrumbs: isSystemDialoguePath,
  });

  useEffect(() => {
    if (pathname.includes('/bot-improvements')) {
      setCurrentView(LibraryViewNavEnum.BOT_IMPROVEMENTS);
    } else if (pathname.includes('/proactivity')) {
      setCurrentView(LibraryViewNavEnum.PROACTIVITY);
    } else if (pathname.includes('/entities')) {
      setCurrentView(LibraryViewNavEnum.ENTITIES);
    } else if (pathname.includes('/evaluation')) {
      setCurrentView(LibraryViewNavEnum.EVALUATION);
    } else if (isSearchOpen) {
      setCurrentView(LibraryViewNavEnum.SEARCH);
    } else if (inSystemDialogues) {
      setCurrentView(LibraryViewNavEnum.SYSTEM);
    } else if (DIALOGUE_PATHS.some((path) => pathname.includes(path))) {
      setCurrentView(LibraryViewNavEnum.DIALOGUE);
    } else {
      setCurrentView(LibraryViewNavEnum.NAVIGATION);
    }
  }, [inSystemDialogues, pathname, isSearchOpen, dispatch, currentView]);

  const libraryNavigationStyles = cx(styles.libraryNavigation, {
    [styles.libraryNavigationsearchView]: isSearchOpen,
  });

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !hasScrolled) {
      setHasScrolled(true);
    } else if (e.target.scrollTop === 0) {
      setHasScrolled(false);
    }
  };

  useEventListener('scroll', handleScroll, libraryNavigationRef.current as EventTarget);

  return (
    <Panel elementRef={setPanelRef} className={styles.libraryPanel}>
      <div className={styles.resize} onMouseDown={onMouseDown} role="gridcell" tabIndex={0} />
      <div className={cx({ [styles.libraryShadow]: hasScrolled })}>
        <LibraryHeader currentView={currentView} setCurrentView={setCurrentView} />
      </div>
      <div className={cx(libraryNavigationStyles)} ref={libraryNavigationRef}>
        {currentView === LibraryViewNavEnum.SEARCH && <LibrarySearch />}
        {currentView === LibraryViewNavEnum.NAVIGATION && <NavigationView setCurrentView={setCurrentView} />}
        {currentView === LibraryViewNavEnum.DIALOGUE && <DialogueView />}
        {currentView === LibraryViewNavEnum.SYSTEM && <SystemView />}
        {currentView === LibraryViewNavEnum.BOT_IMPROVEMENTS && <BotImprovementsView />}
        {currentView === LibraryViewNavEnum.PROACTIVITY && <ProactivityView />}
      </div>
    </Panel>
  );
};

export default Library;
