import { useMutation, useQuery } from '@apollo/client';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { get, isNil, isPlainObject, mapValues, omit, omitBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  type ChatbubbleGlobalIconType,
  type ChatbubbleGlobalIconsType,
  type ChatbubblePushGreetingType,
  type ChatbubbleSettingsQuery,
  type ChatbubbleSettingsType,
  UpdateChatbubbleSettingsDocument,
} from 'frontend/api/generated';
import ChatbubbleSettings from 'frontend/features/BotSettings/Chatbubble/queries/ChatbubbleSettings.query.graphql';
import { useBotOrSkill, useCurrentLanguage, useLanguages, useOnSubmitWithUpload, useToast } from 'frontend/hooks';
import { chatBubbleSettingsUpdated } from 'frontend/state/dux/bot';
import { getArrayFieldUpdate } from 'frontend/utils';

// Note: Global icon images
// There was an issue with global icons (more generic, but more obvious there) and images
// The issue is that we upload images before the global icon created, so we can add the url that the backend returns in the mutation
// This creates an issue in the backend side cause images have no owner in that case
// To workaround this, we save image urls as base64 on create
// And set proper url on update (after global icon exists)
// TODO backend to follow up on this issue

const defaultColors = Object.freeze({
  colorChatLogElements: '#333333',
  colorButtonOutline: '#0069ff',
  colorBackground: '#ffffff',
  colorHeaderBackground: '#0069ff',
  colorHeaderText: '#ffffff',
  colorButtonBackground: '#0069ff',
  colorBubbleButtonBackground: '#0069ff',
  colorButtonText: '#ffffff',
  colorInputBackground: '#f3f3f3',
  colorInputText: '#333333',
  colorUserMessageBackground: '#0069ff',
  colorUserMessageTextColor: '#ffffff',
  colorBotMessageBackground: '#f5f5f5',
  colorBotMessageTextColor: '#2c2c2c',
  colorGlobalIconsButtonBackground: '#ffffff',
  colorPanelBackground: '#ffffff',
  colorSecondaryButtonBackground: '#ffffff',
});

const restoreDefaultColors = (change) => {
  Object.entries(defaultColors).forEach(([name, color]) => {
    change(name, color);
  });
};

const pushGreetingFields = ['title', 'url', 'enabled', 'message', 'autopopupTime', 'id'];

const globalIconsFields = ['title', 'url', 'enabled', 'triggerOnDevice', 'triggerRule', 'triggerAfter', 'icons'];

const addLanguageDefaults = (currentLanguage) => (field) => {
  if (!isPlainObject(field) || !field.en) {
    return field;
  }
  return { ...{ [currentLanguage]: field.en }, ...field };
};

export default ({
  uploadUrl,
  toastSuccessMessage,
  onSuccess,
}: {
  uploadUrl?: string;
  toastSuccessMessage?: string;
  onSuccess?: (data: NonNullable<ChatbubbleSettingsQuery>['chatbubbleSettings'] | null) => any;
} = {}) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const botOrSkillParams = useBotOrSkill();
  const { botId } = botOrSkillParams;
  const [{ currentLanguage }] = useCurrentLanguage();

  const [updateSettings] = useMutation(UpdateChatbubbleSettingsDocument, {
    update: (_cache, { data }) => {
      toast.success(toastSuccessMessage || 'Settings updated');
      dispatch(chatBubbleSettingsUpdated(true));
      onSuccess?.(data?.updateChatbubbleSettings ?? null);
    },
  });
  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);
  const { data, loading } = useQuery(ChatbubbleSettings, { variables: { botId } });

  const initialValues = useMemo(
    () =>
      mapValues(omit(get(data, 'chatbubbleSettings', {}), ['id', '__typename']), addLanguageDefaults(currentLanguage)),
    [currentLanguage, data],
  );

  const onSubmit = useCallback(
    async (values: any, _form?: any, uploadData?: any, setFile?: any) => {
      const {
        created: createdPushGreetings,
        updated: updatedPushGreetings,
        deleted: deletedPushGreetings,
      } = getArrayFieldUpdate({ ...initialValues }.pushGreetings, { ...values }.pushGreetings, pushGreetingFields);

      const {
        created: createdGlobalIcons,
        updated: updatedGlobalIcons,
        deleted: deletedGlobalIcons,
      } = getArrayFieldUpdate({ ...initialValues }.globalIcons, { ...values }.globalIcons, globalIconsFields);

      const chatbubblePersistStateLifespanSeconds = parseInt(values.chatbubblePersistStateLifespanSeconds, 10);
      const variables = {
        botId: botId!,
        chatbubbleSettingsInput: {
          ...omit(values, [
            'id',
            '__typename',
            'pushGreetings',
            'globalIcons',
            'chatbubblePersistStateLifespanSeconds',
            'chatbubbleIconAvatarUrl',
            'welcomePageImageUrl',
          ]),
          chatbubblePersistStateLifespanSeconds: !Number.isNaN(chatbubblePersistStateLifespanSeconds)
            ? chatbubblePersistStateLifespanSeconds
            : 60 * 60 * 24 * 7,
          createdPushGreetings: (createdPushGreetings as unknown as ChatbubblePushGreetingType[])
            .slice()
            .reverse()
            .map((pushGreeting) => {
              const copyPushGreeting = {
                ...pushGreeting,
                title: {
                  ...pushGreeting.title,
                },
              };
              // We set the same title to all languages for that bot
              // prolly should be done on the backend?
              const [entry] = Object.entries(copyPushGreeting.title);

              for (let i = 0; i < languages.length; i += 1) {
                const code = languages[i]?.code || entry?.[0];
                copyPushGreeting.title[code as string] = entry?.[1];
              }

              return copyPushGreeting;
            }), // Make sure topmost is last created
          updatedPushGreetings,
          deletedPushGreetings,
          createdGlobalIcons: (createdGlobalIcons as unknown as ChatbubbleGlobalIconsType[])
            .slice()
            .reverse()
            .map((globalIcon) => {
              // title property is read only, so we need to make a copy of it to mutate
              // and remove tempId due to duplicate functionality
              const copyGlobalIcon = omit(
                {
                  ...globalIcon,
                  title: {
                    ...globalIcon.title,
                  },
                },
                ['tempId'],
              );

              // We set the same title to all languages for that bot
              // prolly should be done on the backend?
              const [entry] = Object.entries(copyGlobalIcon.title);

              for (let i = 0; i < languages.length; i += 1) {
                const code = languages[i]?.code || entry?.[0];
                copyGlobalIcon.title[code as string] = entry?.[1];
              }

              return {
                ...copyGlobalIcon,
                icons: copyGlobalIcon.icons?.map((icon: Maybe<ChatbubbleGlobalIconType & { tempId?: string }>) => ({
                  ...omitBy(omit({ ...icon }, ['tempId', 'index']), isNil),
                  ...(uploadData && { imageUrl: uploadData.find((ud) => ud.tempId === icon?.tempId)?.url }),
                })),
              };
            }), // Make sure topmost is last created
          updatedGlobalIcons: (updatedGlobalIcons as unknown as ChatbubbleGlobalIconsType[]).map((globalIcon) => ({
            ...globalIcon,
            icons: globalIcon.icons?.map((icon: Maybe<ChatbubbleGlobalIconType & { tempId?: string }>) => {
              const uploadedImage = uploadData?.find((ud) => ud.tempId === icon?.tempId);
              return {
                ...omitBy(omit({ ...icon }, ['tempId', 'index']), isNil),
                ...((uploadedImage?.url || icon?.imageUrl) && { imageUrl: uploadedImage?.url || icon?.imageUrl }),
              };
            }),
          })),
          deletedGlobalIcons,
          deleteChatbubbleIconAvatar: values.chatbubbleIconAvatarUrl === null,
          deleteWelcomePageImage: values.welcomePageImageUrl === null,
        },
      };

      // @ts-expect-error: variables is a mess of nested nullable types
      await updateSettings({ variables });
      setFile?.(undefined);
    },
    [botId, updateSettings, initialValues, languages],
  );

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(
    onSubmit,
    uploadUrl || `api/v2/bot/${botId}/upload-welcome-page/`,
  );

  return {
    onSubmit,
    onSubmitWithUpload: onSubmitWithUpload as (values: Partial<ChatbubbleSettingsType>) => void,
    setFile,
    languages,
    languagesLoading,
    loading,
    initialValues,
    currentLanguage,
    restoreDefaultColors,
    botId,
  };
};
