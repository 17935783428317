import { curry, get } from 'lodash';

import { isDialogueFollowupTo } from 'frontend/api/queries';
import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import { GetDialogue } from 'frontend/features/Build/graphql';
import { pluralize } from 'frontend/utils';

import { updateDialogueParentOnCreate, updateDialogueParentOnRemove } from '../../cacheHelpers';
import { dialogueCanHaveFollowups } from '../../utils';

const fetchPolicy = 'network-only';

export const dropDialogue = async ({
  id,
  client,
  toast,
  buildIdObject,
  skillId,
  dialogueInDialogue,
  item,
  currentTitle,
  currentLanguage,
}) => {
  const { data } = await client.query({
    query: isDialogueFollowupTo,
    variables: { ...buildIdObject, parentId: item.id, dialogueId: id },
  });
  if (get(data, 'dialogue.isFollowupTo')) {
    toast.error('Cannot drop dialogue in its own followup');
    return;
  }

  let result;
  try {
    result = await dialogueInDialogue({
      variables: { sourceDialogueId: item.id, targetDialogueId: id, skillId, ...buildIdObject },
    });
  } catch (error) {
    toast.error(error.message);
    return;
  }
  const addedDialogue = get(result, 'data.dropDialogueInDialogue');

  // Update followups and followup count of original parent
  updateDialogueParentOnRemove({ dialogue: item, buildIdObject, client });
  // Update followups and followup count of new parent
  updateDialogueParentOnCreate({ buildIdObject, client, dialogue: addedDialogue });

  toast.success(`Dialogue '${item.title[currentLanguage]}' was made a followup to '${currentTitle}'`);
};

export const dropSamples = async ({ id, client, toast, buildIdObject, samplesInDialogue, item }) => {
  if (item.dialogueHasChanges) {
    toast.error('You must save your changes before you can move samples');
    return;
  }

  const variables = { sourceDialogueId: item.dialogueId, targetDialogueId: id, sampleIds: item.sampleIds };
  await samplesInDialogue({ variables });

  const updateQueries = [
    client.query({ query: GetDialogue, variables: { ...buildIdObject, dialogueId: item.dialogueId }, fetchPolicy }),
    client.query({ query: GetDialogue, variables: { ...buildIdObject, dialogueId: id }, fetchPolicy }),
  ];
  await Promise.all(updateQueries);

  const nSamples = item.sampleIds.length;
  toast.success(`${nSamples} ${pluralize('sample', nSamples)} moved!`);
};

export const canDrop = curry((dialogue, item) => {
  const { id, dialogueType } = dialogue;

  if (item.type === dragAndDropTypes.DIALOGUE) {
    return dialogueCanHaveFollowups(dialogue) && ![item.id, item.parentId, item.dialogueModParentId].includes(id);
  }

  if (item.type === dragAndDropTypes.SAMPLES) {
    return dialogueType === DIALOGUE_TYPES.SAMPLES && Boolean(item.dialogueId) && id !== item.dialogueId;
  }

  return false;
});
