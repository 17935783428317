import { useMemo } from 'react';

type Style = {
  position: 'relative';
  left: string;
  width: string;
};

export default function useIndentationStyle(indentationLevel: number): Style {
  return useMemo((): Style => {
    const indentationPixels = indentationLevel * 15;

    return {
      position: 'relative',
      left: `${indentationPixels}px`,
      width: `calc(100% - ${indentationPixels}px)`,
    };
  }, [indentationLevel]);
}
