import { capitalize } from 'lodash';
import { useState } from 'react';

import type { DialogueType } from 'frontend/constants/dialogueTypes';
import { type BreadCrumb, isSpecialDialoguePath } from 'frontend/features/Library/utils/breadcrumbs';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { useSpecialDialogueContextMenu } from './hooks';
import BottomFolder from '../BottomFolder';

type Props = {
  buildIdObject: BuildIdObject;
  dialogueType: DialogueType;
};

function SpecialDialogueFolder({ buildIdObject, dialogueType }: Props) {
  const dialogueTypeStr = dialogueType.toLowerCase() as 'greeting' | 'fallback' | string;

  const { actions, contextMenuId } = useSpecialDialogueContextMenu({ dialogueType });
  const [showLoader, setShowLoader] = useState(false);

  const checkBreadcrumbs = (path: string, breadcrumbs: BreadCrumb[] | null) =>
    isSpecialDialoguePath(dialogueTypeStr, path, breadcrumbs);

  return (
    <BottomFolder
      buildIdObject={buildIdObject}
      dialogueType={dialogueType}
      name={capitalize(dialogueTypeStr)}
      actions={actions}
      contextMenuId={contextMenuId}
      checkBreadcrumbs={checkBreadcrumbs}
      showLoader={showLoader}
      setShowLoader={setShowLoader}
      indentationLevel={0}
    />
  );
}

export default SpecialDialogueFolder;
