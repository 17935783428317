import type { ButtonsOverviewQuery } from 'frontend/api/generated';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import { getId } from 'frontend/utils';

type PartialButton = ButtonsOverviewQuery['buttons']['buttons'][number];
type PartialDialogue = NonNullable<PartialButton['dialogue']>;

export default function compileButtonList(
  languageCode: string,
  buttons: PartialButton[],
): {
  key: string;
  value: string;
  actions: { button: PartialButton; id: string };
  dialogue: {
    title: string;
    id: string;
    dialogueType: DialogueType;
    isActive: boolean;
  };
}[] {
  return buttons.map(({ dialogue, value, ...button }) => {
    const { title, id, dialogueType, isActive } = dialogue as PartialDialogue;
    return {
      ...button,
      value: value ?? '',
      dialogue: {
        title: title?.[languageCode] ?? title.default ?? '',
        id,
        dialogueType,
        isActive: isActive[languageCode] ?? false,
      },
      key: `button${getId(button)}`,
      actions: { button, id },
    };
  });
}
