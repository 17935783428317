import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BotBackupsDocument, CreateBotBackupDocument, DownloadBotBackupDocument } from 'frontend/api/generated';
import { Button, Loader, PageContent } from 'frontend/components';
import { getPusherChannel, usePusherEvent } from 'frontend/features/Pusher';
import { useIsRunning } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

import { Backups } from './components';

const BotBackups = () => {
  const { botId } = useParams();
  const navigate = useNavigate();
  const { loading: backupsLoading, data: backupsData } = useQuery(BotBackupsDocument, { variables: { botId: botId! } });

  const updateOnDownload = (cache, { data }: { data?: any }) => {
    const url = data.downloadBotBackup.url;
    if (url) window.location.assign(data.downloadBotBackup.url);
  };
  const [download] = useMutation(DownloadBotBackupDocument, { update: updateOnDownload });

  const updateOnCreate = (cache, { data }: { data?: any }) =>
    cache.modify({ fields: { botBackups: (current = []) => [data.createBotBackup, ...current] } });
  const [create] = useMutation(CreateBotBackupDocument, { update: updateOnCreate, variables: { botId: botId! } });
  const [createWithSubmitting, isSubmitting, onFinished] = useIsRunning(create, { updateWhenFinished: false });

  const onClickBack = useCallback(
    (event) => {
      event.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  const data = useMemo(
    () =>
      (backupsData?.botBackups || []).map((backup) => {
        const { id, status, createdAt } = backup;
        return {
          key: id,
          id,
          status,
          created: format(createdAt, DATE_FORMAT_WITH_TIME),
          options: {
            botId,
            backup,
            type: 'BOT',
            downloadBackup: (backupId) => download({ variables: { botId: botId!, backupId } }),
          },
        };
      }),
    [backupsData, botId, download],
  );

  usePusherEvent(getPusherChannel({ botId }), 'backup', onFinished);

  if (backupsLoading) return <Loader size="large" />;

  return (
    <PageContent>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button size="small" onClick={onClickBack}>
            Go back
          </Button>
        </div>
        <div>
          <Button color="primary" onClick={createWithSubmitting} isSubmitting={isSubmitting} disabled={isSubmitting}>
            Create backup
          </Button>
        </div>
      </div>
      <Backups backups={data} />
    </PageContent>
  );
};

export default BotBackups;
