import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { BreadCrumbCheck } from 'frontend/features/Library/utils/breadcrumbs';
import { usePrevious } from 'frontend/hooks';
import { selectCurrentBreadcrumbs } from 'frontend/state/dux/build';

const defaultCheckBreadcrumbs: BreadCrumbCheck = () => false;

type Props = {
  toggleOpen?: CallableFunction;
  open?: boolean;
  checkBreadcrumbs: BreadCrumbCheck;
  autoOpen: boolean;
};

export default function useBreadcrumbs({
  toggleOpen,
  open,
  checkBreadcrumbs = defaultCheckBreadcrumbs,
  autoOpen = true,
}: Props) {
  const currentBreadcrumbs = useSelector(selectCurrentBreadcrumbs);
  const { pathname } = useLocation();
  const isInBreadcrumbs = checkBreadcrumbs(pathname, currentBreadcrumbs);
  const prevIsInBreadcrumbs = usePrevious(isInBreadcrumbs);

  useEffect(() => {
    if (toggleOpen && autoOpen && isInBreadcrumbs && !prevIsInBreadcrumbs && !open) {
      toggleOpen();
    }
  }, [autoOpen, isInBreadcrumbs, open, prevIsInBreadcrumbs, toggleOpen]);

  return { isInBreadcrumbs };
}
