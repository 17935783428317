import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import {
  $getSelection,
  BLUR_COMMAND,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  type LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './FloatingToolbarPlugin.scss';
import isSelectionLink from '../utils/isSelectionLink';
import ToolbarContent from './components/ToolbarContent/ToolbarContent';

const APP_CONTAINER_ELEMENT = document.getElementById('app')!;

function FloatingToolbarComponent({ editor }: { editor: LexicalEditor }) {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = window.getSelection();
    const lexicalSelection = $getSelection();
    const toolbarElement = toolbarRef.current;
    const rootElement = editor.getRootElement();

    if (!selection || isSelectionLink(lexicalSelection) || !toolbarElement || !rootElement || selection.isCollapsed) {
      setIsVisible(false);
      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    if (rect.width === 0 || rect.height === 0) {
      setIsVisible(false);
      return;
    }

    setIsVisible(true);
    toolbarElement.style.top = `${rect.top + window.scrollY - toolbarElement.offsetHeight - 8}px`;
    toolbarElement.style.left = `${rect.left + window.scrollX - 8}px`;
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => updateToolbar());
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        /* When the editor loses focus, hide the toolbar after a short delay. */
        editor.registerCommand(
          BLUR_COMMAND,
          () => {
            setTimeout(() => {
              if (!toolbarRef.current?.contains(document.activeElement)) {
                setIsVisible(false);
              }
            }, 0);
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        /* When the editor is clicked, show the toolbar if the selection is a link. */
        editor.registerCommand(
          CLICK_COMMAND,
          () => {
            const selection = $getSelection();
            if (isSelectionLink(selection)) {
              setIsVisible(true);
            }

            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, updateToolbar],
  );

  return createPortal(
    <div
      ref={toolbarRef}
      className={cx(styles.floatingToolbar, { [styles.isVisible]: isVisible })}
      onBlur={(e) => {
        if (!toolbarRef.current?.contains(e.relatedTarget)) {
          setIsVisible(false);
        }
      }}
    >
      <ToolbarContent editor={editor} setToolbarVisible={setIsVisible} />
    </div>,
    APP_CONTAINER_ELEMENT,
  );
}

export default function FloatingToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  return <FloatingToolbarComponent editor={editor} />;
}
