import { useMemo } from 'react';

import useMe from 'frontend/hooks/useMe';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

const useFiltersPermissions = ({ compareUserId }) => {
  const { data } = useMe();
  const { platformPermissions } = useMyPermissions();

  // Kindly admins
  // the user that has created the filter
  // and workspace/organization admins can edit the saved filters
  const canEdit = useMemo(() => {
    if (compareUserId && data?.me?.id === compareUserId) return true;

    if (
      ['dashboard.manage_bot', 'organizations.manage_organization'].some((permission) =>
        platformPermissions?.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  }, [data, platformPermissions, compareUserId]);

  return { canEdit };
};

export default useFiltersPermissions;
