import cx from 'classnames';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Link, useLocation } from 'react-router-dom';

import { Loader } from 'frontend/components';
import ContextMenu from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES, dragAndDropTypes, isRegularDialogueType } from 'frontend/constants';
import { dialogueInBreadcrumbsChecker } from 'frontend/features/Library/utils/breadcrumbs';
import { useCombineRefs, useCurrentLanguage, useRemoveDefaultDragPreview } from 'frontend/hooks';
import { BuildIdObjectType, IDType } from 'frontend/propTypes';

import styles from './DialogueItem.scss';
import SampleCount from './SampleCount';
import { useDialogueContextMenu, useDropInDialogue } from './hooks';
import { canDrop } from './utils';
import libraryStyles from '../../Library.scss';
import { useBreadcrumbs, useIndentationStyle } from '../../hooks';
import { LibraryDialogueType } from '../../propTypes';
import { getArrowClassnames, getBuildUrl, getFolderClassnames, isSubscriptionDialogue } from '../../utils';
import DialogueIcon from '../DialogueIcon';
import LibraryItemContents from '../LibraryItemContents';

export default function DialogueItem({
  dialogue,
  buildIdObject,
  skillId,
  parentDisabled,
  selected,
  indentationLevel = 1,
  hideFollowups = false,
  showContextMenu = true,
}) {
  const { pathname } = useLocation();
  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();

  const { id, title, numberOfFollowups, parentId, mod, isActive, dialogueType, colorLabel } = dialogue;
  const dialogueIsActive = mod?.modIsActive ?? isActive;
  const dialogueColorLabel = mod?.modColorLabel ?? colorLabel;
  const isFollowup = parentId;
  const hasFollowups = !hideFollowups && numberOfFollowups + (mod?.numberOfModFollowups ?? 0) > 0;
  const url = getBuildUrl({ buildIdObject, dialogueType, target: id });
  const disabled = !dialogueIsActive[selectedLanguage] || parentDisabled;
  const isSubscription = isSubscriptionDialogue({ buildIdObject, skillId });
  const currentTitle = title[currentLanguage] || title.default;

  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prevOpen) => !prevOpen);

  const { isInBreadcrumbs } = useBreadcrumbs({
    autoOpen: false,
    open,
    toggleOpen,
    checkBreadcrumbs: dialogueInBreadcrumbsChecker(id),
  });

  const { actions, contextMenuId } = useDialogueContextMenu({
    dialogue,
    selectedLanguage,
    disabled,
    parentDisabled,
    skillId,
    isSubscription,
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item: {
      ...pick(dialogue, ['id', 'dialogueType', 'title', 'topicId', 'parentId', 'dialogueModParentId']),
      type: dragAndDropTypes.DIALOGUE,
    },
    type: dragAndDropTypes.DIALOGUE,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: () => (Boolean(mod) || !isSubscription) && isRegularDialogueType(dialogueType),
  });

  const drop = useDropInDialogue({ id, setShowLoader, skillId, currentTitle, currentLanguage: selectedLanguage });

  const [{ isOver, canDrop: canDropOnCurrent }, dropRef] = useDrop({
    accept: [dragAndDropTypes.DIALOGUE, dragAndDropTypes.SAMPLES],
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
    canDrop: canDrop(dialogue),
    drop,
  });

  const dragAndDropRef = useCombineRefs(dragRef, dropRef);
  useRemoveDefaultDragPreview(previewRef);

  const indentationStyle = useIndentationStyle(indentationLevel);
  const active = pathname === url || (isInBreadcrumbs && !open) || selected;
  const itemClassName = getFolderClassnames({
    isFollowup,
    hasFollowups,
    url,
    disabled,
    isDragging,
    canDrop: canDropOnCurrent,
    isOver,
    active,
  });
  const titleClassName = cx(
    styles.dialogueTitle,
    { [styles.dialogueTitleActive]: active },
    getArrowClassnames(open, { hide: !hasFollowups }),
  );

  const loaderClassnames = cx(libraryStyles.libraryLoader, libraryStyles.libraryLoaderDialogue);

  return (
    <>
      <ContextMenu
        id={contextMenuId}
        disabled={actions.length === 0 || !showContextMenu}
        overlay={<MenuOverlay options={actions} />}
      >
        <Link
          onClick={active ? toggleOpen : () => setOpen(true)}
          to={url}
          className={itemClassName}
          ref={dragAndDropRef}
          title={currentTitle}
        >
          <span style={indentationStyle as React.CSSProperties} className={titleClassName}>
            <span className={styles.sampleWrapper}>
              <DialogueIcon
                dialogueType={dialogueType}
                colorLabel={dialogueColorLabel}
                currentLanguage={currentLanguage}
              />
              <span className={styles.sample} translate="no">
                {currentTitle}
                {showLoader && <Loader className={loaderClassnames} />}
              </span>
            </span>
            <SampleCount dialogue={dialogue} active={active} />
          </span>
        </Link>
      </ContextMenu>
      {hasFollowups && open && (
        <LibraryItemContents
          buildIdObject={buildIdObject}
          selectedLanguage={selectedLanguage}
          dialogueId={id}
          dialogueModId={mod?.id}
          skillId={skillId}
          parentDisabled={disabled}
          dialogueType={DIALOGUE_TYPES.REGULAR}
          indentationLevel={indentationLevel + 1}
          setShowLoader={setShowLoader}
        />
      )}
    </>
  );
}

DialogueItem.propTypes = {
  skillId: IDType,
  buildIdObject: BuildIdObjectType.isRequired,
  parentDisabled: PropTypes.bool,
  /** If true, show the context menu on right click.
   * @default true
   */
  showContextMenu: PropTypes.bool,
  dialogue: LibraryDialogueType.isRequired,
  indentationLevel: PropTypes.number,
  selected: PropTypes.bool,
  hideFollowups: PropTypes.bool,
};
