/* eslint-disable camelcase */

import { omit } from 'lodash';
import { useMemo } from 'react';

import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import useCampaignId from './useCampaignId';
import useDateRanges from './useDateRanges';
import useGranularity from './useGranularity';
import useLanguageCodes from './useLanguageCodes';
import useNudge from './useNudge';
import useSessionType from './useSessionType';
import useSource from './useSource';
import useTimezone from './useTimezone';

type Params = { skip?: string[]; extra?: Record<string, QueryParam> };
export default function useFilters({ skip, extra }: Params = { skip: [], extra: {} }): Record<string, QueryParam> {
  const { from, to } = useDateRanges();
  const [languageCodes] = useLanguageCodes();
  const [sources] = useSource();
  const [granularity] = useGranularity();
  const { timezone: tz } = useTimezone();
  const [nudge_id] = useNudge();
  const [campaign_id] = useCampaignId();
  const [type] = useSessionType();

  const filters = useMemo(() => {
    const allFields = { from, to, tz, granularity, languageCodes, sources, nudge_id, campaign_id, type };
    return {
      ...(skip ? omit(allFields, skip) : allFields),
      ...(extra || {}),
    };
  }, [extra, from, tz, granularity, languageCodes, skip, sources, to, nudge_id, campaign_id, type]);

  return filters;
}
