import cx from 'classnames';
import { useId } from 'react';
import { type Control, Controller } from 'react-hook-form';

import styles from './ToneOfVoiceRadio.scss';

export type Tone = 'CHEEKY' | 'PLAYFUL' | 'INFORMAL' | 'FORMAL' | 'CORPORATE';

interface ToneOption {
  /**
   * The label of the tone option
   */
  label: string;
  /**
   * The explanation of the tone option
   */
  labelExplanation?: string;
  /**
   * The example text of the tone option
   */
  exampleText?: string;
}

export const toneOptions: Record<Tone, ToneOption> = {
  CHEEKY: {
    label: 'Cheeky',
    labelExplanation: 'Witty and playful tone',
    exampleText: 'Well well well, look who needs some help! *winks* What can I do for you today, friend?',
  },
  PLAYFUL: {
    label: 'Playful',
    labelExplanation: 'Friendly and approachable tone',
    exampleText: 'Hey there! Ready for an awesome chat? Let me know what you need help with!',
  },
  INFORMAL: {
    label: 'Informal',
    labelExplanation: 'Casual and friendly tone',
    exampleText: "Hey! What's up? Just chilling here ready to help you out with whatever you need!",
  },
  FORMAL: {
    label: 'Formal',
    labelExplanation: 'Professional tone',
    exampleText: 'Good day! I am your dedicated assistant. How may I be of service to you today?',
  },
  CORPORATE: {
    label: 'Corporate',
    labelExplanation: 'Corporate tone',
    exampleText:
      'Dear valued customer, thank you for reaching out to our support services. Please state your inquiry and I will assist you accordingly.',
  },
};

interface ToneOfVoiceRadioProps {
  name: string;
  control: Control<any>;
}

export default function ToneOfVoiceRadio({ name, control }: ToneOfVoiceRadioProps) {
  const id = useId();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div>
          <div className={styles.options}>
            {Object.values(toneOptions).map((option) => (
              <label
                key={option.label}
                htmlFor={`${id}-${option.label}`}
                className={cx(styles.optionLabel, {
                  [styles.selected]: value.toLowerCase() === option.label.toLowerCase(),
                })}
              >
                <span className={styles.label}>{option.label}</span>
              </label>
            ))}
          </div>

          <div className={styles.sliderContainer}>
            <div className={styles.sliderLine} />
            <div className={styles.dotsContainer}>
              {Object.values(toneOptions).map((option) => {
                const isSelected = value.toLowerCase() === option.label.toLowerCase();
                const radioValue = option.label.toUpperCase();

                return (
                  <label key={option.label} className={styles.dotWrapper}>
                    <input
                      type="radio"
                      value={radioValue}
                      checked={isSelected}
                      onChange={() => onChange(radioValue)}
                      className="visually-hidden"
                      id={`${id}-${option.label}`}
                      aria-label={`Select ${option.label} tone`}
                    />
                    <div
                      className={cx(styles.dot, {
                        [styles.selectedDot]: isSelected,
                      })}
                    />
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      )}
    />
  );
}
