import { gql } from '@apollo/client';

const BUTTON_DIALOGUE_FRAGMENT = gql`
  fragment ButtonDialogue on DialogueType {
    id
    title
    dialogueType
    colorLabel
  }
`;

export default gql`
  query Buttons($botId: ID!, $buttonIds: [UUID!]!, $includeDialogue: Boolean!) {
    # Hacky way to circumvent pagination by setting the pageSize to a high number
    buttons(botId: $botId, buttonIds: $buttonIds, pageSize: 5000, page: 1) {
      id
      page
      buttons {
        id
        value
        label
        languageCode
        buttonType
        dialogue @include(if: $includeDialogue) {
          ...ButtonDialogue
        }
        dialogueMod @include(if: $includeDialogue) {
          id
          modDialogue {
            ...ButtonDialogue
          }
        }
        dialogueLink @include(if: $includeDialogue) {
          id
          dialogue {
            ...ButtonDialogue
          }
        }
      }
    }
  }
  ${BUTTON_DIALOGUE_FRAGMENT}
`;
