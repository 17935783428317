import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { addListItemByFragment } from 'frontend/api/cacheHelpers';
import LabelFragment from 'frontend/api/fragments/Label.fragment.graphql';
import { LabelColorsDocument, type LabelType } from 'frontend/api/generated';
import { CREATE_LABEL, UPDATE_LABEL } from 'frontend/api/mutations';
import { Edit, SquarePlus } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { LABEL_PHASES } from 'frontend/features/Labels/constants';
import { noemoji } from 'frontend/form/validators';
import { useToast } from 'frontend/hooks';
import type { Label } from 'frontend/propTypes/LabelType';

import styles from './LabelsCreatorAndUpdater.scss';
import LabelRow from '../LabelRow/LabelRow';

type LabelColors = {
  color: string;
  background: string;
};
type LabelPhaseType = (typeof LABEL_PHASES)[keyof typeof LABEL_PHASES];

interface LabelsCreatorAndUpdaterProps {
  labelManagerPhase: string;
  labelToCreate: string;
  labelToEdit: Label;
  resetLabelsState: () => void;
  setLabelManagerPhase: (val: LabelPhaseType) => void;
}

export default function LabelsCreatorAndUpdater({
  labelToCreate,
  labelManagerPhase,
  setLabelManagerPhase,
  labelToEdit,
  resetLabelsState,
}: LabelsCreatorAndUpdaterProps) {
  const [selectedColor, setSelectedColor] = useState({
    name: 'PINK',
    color: '#C52B75',
    background: '#FDECF9',
  });

  const toast = useToast();
  const { botId } = useParams();
  const { data: colorsData } = useQuery(LabelColorsDocument);
  const [createLabelMutation] = useMutation(CREATE_LABEL);
  const [updateLabelMutation] = useMutation(UPDATE_LABEL);

  const colors = useMemo(() => {
    const allColors = colorsData?.colors ?? {};
    const { GRAY: _systemLabelColor, ...colorsWithoutGray } = allColors;
    return colorsWithoutGray;
  }, [colorsData]) as LabelColors;

  const isInputValid = () => {
    if (noemoji(labelToCreate)) {
      toast.error('No emoji allowed');
      return false;
    }
    if (labelToCreate.trim().length === 0) {
      toast.error('No empty label allowed');
      return false;
    }

    return true;
  };

  const isUpdateValid = () => {
    if (labelToEdit.color === selectedColor.color && labelToEdit.name === labelToCreate.trim()) {
      toast.error('No changes were made: change at the least the name or the color');
      return false;
    }

    return true;
  };

  /* When editing a label, the starting color for the new label will be the same of the old one. */
  useEffect(() => {
    if (!labelToEdit || labelManagerPhase !== LABEL_PHASES.EDIT) return;

    setSelectedColor({
      name: Object.keys(colors).find((key) => colors[key].color === labelToEdit.color) as string,
      color: labelToEdit.color as string,
      background: labelToEdit.background as string,
    });
  }, [labelToEdit, colors, labelManagerPhase]);

  const createLabel = async (name, color) => {
    const update = (cache, { data }: { data?: { createLabel: LabelType } }) => {
      cache.modify({
        fields: {
          labelsForBot: addListItemByFragment(cache, LabelFragment, data?.createLabel),
        },
      });
    };

    await createLabelMutation({ variables: { botId, color, name }, update });
    toast.success(`Label "${name}" created`);
  };

  const updateLabel = async (name, color, labelId) => {
    await updateLabelMutation({ variables: { botId, labelId, color, name } });
    toast.success(
      labelToEdit.name === name
        ? `Label "${labelToEdit.name}" is now ${color.toLowerCase()}`
        : `Label "${labelToEdit.name}" updated to "${name}"`,
    );
  };

  const handleCreate = (e) => {
    e.stopPropagation();
    setLabelManagerPhase(LABEL_PHASES.ADD);
  };

  const handleSave = (e) => {
    if (!isInputValid()) return;
    e.stopPropagation();
    createLabel(labelToCreate, selectedColor.name);
    resetLabelsState();
  };

  const handleEdit = () => {
    if (!isInputValid() || !isUpdateValid()) return;

    updateLabel(labelToCreate, selectedColor.name, labelToEdit.id).then(() => {
      resetLabelsState();
    });
  };

  return (
    <>
      <LabelRow
        subText="New"
        label={{
          name: labelToCreate,
          color: selectedColor.color,
          background: selectedColor.background,
        }}
      >
        {labelManagerPhase === LABEL_PHASES.DEFAULT && (
          <Button flat className={styles.button} icon={SquarePlus} text="Create" onClick={handleCreate} />
        )}
        {labelManagerPhase === LABEL_PHASES.ADD && (
          <Button flat className={styles.button} icon={SquarePlus} text="Save" onClick={handleSave} />
        )}
        {labelManagerPhase === LABEL_PHASES.EDIT && (
          <Button flat className={styles.button} icon={Edit} text="Update" onClick={handleEdit} />
        )}
      </LabelRow>

      {(labelManagerPhase === LABEL_PHASES.ADD || labelManagerPhase === LABEL_PHASES.EDIT) && (
        <>
          <p className={styles.colorText}>Select a color</p>
          <div className={styles.colorsContainer}>
            {(Object.entries(colors) as unknown as [string, { color: string; background: string }][]).map(
              ([name, { color, background }]) => (
                <button
                  key={name}
                  aria-label={`Color ${name}`}
                  className={styles.color}
                  onClick={() => setSelectedColor({ name, color, background })}
                  // @ts-expect-error set variable
                  style={{ '--_background': background }}
                  type="button"
                >
                  {selectedColor.color === color && (
                    <svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg" fill={color} className={styles.dot}>
                      <circle cx="3" cy="3" r="3" />
                    </svg>
                  )}
                </button>
              ),
            )}
          </div>
        </>
      )}
    </>
  );
}
