import { type MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS, MAP_BUTTON_TYPE } from './constants';

export interface ButtonsState {
  search: string;
  buttonTypes: Record<string, boolean>;
  orderBy: keyof typeof MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS;
  descending: boolean;
  page: number;
}

export type ButtonsAction =
  | { type: 'SET_SEARCH'; payload: string }
  | { type: 'SET_BUTTON_TYPES'; payload: Record<string, boolean> }
  | { type: 'SET_ORDER_BY'; payload: keyof typeof MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS }
  | { type: 'SET_DESCENDING'; payload: boolean }
  | { type: 'SET_PAGE'; payload: number };

export const initialState: ButtonsState = {
  search: '',
  buttonTypes: Object.fromEntries(Object.keys(MAP_BUTTON_TYPE).map((key) => [key, true])),
  orderBy: 'buttonType',
  descending: false,
  page: 1,
};

export function buttonsReducer(state: ButtonsState = initialState, action: ButtonsAction): ButtonsState {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...state, search: action.payload, page: 1 };
    case 'SET_BUTTON_TYPES':
      return { ...state, buttonTypes: action.payload, page: 1 };
    case 'SET_ORDER_BY':
      return { ...state, orderBy: action.payload };
    case 'SET_DESCENDING':
      return { ...state, descending: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    default:
      return state;
  }
}
