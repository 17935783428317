import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'frontend/features/Build/components/DialoguePath/DialoguePath.scss';
import type { LibraryItemType } from 'frontend/features/Build/components/DialoguePath/constants';
import { getParentUrl } from 'frontend/features/Build/components/DialoguePath/utils';
import type { BuildType } from 'frontend/propTypes/BuildIdObjectType';

export function ParentElement({
  name,
  currentLanguage,
  id,
  buildType,
  buildId,
  type,
  hasFolderParentInCollapsed,
}: {
  name: { default: string };
  currentLanguage: string;
  id: string;
  buildType: BuildType;
  buildId: string;
  type: LibraryItemType;
  hasFolderParentInCollapsed: boolean;
}) {
  return (
    <Link
      to={getParentUrl(buildId, buildType, type, id)}
      className={cx(styles.parentElement, { [styles.hasFolderParentInCollapsed]: hasFolderParentInCollapsed })}
    >
      {name[currentLanguage] || name.default}
    </Link>
  );
}
