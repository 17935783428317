import cx from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ChevronLeftSquare, Dashboard, SpeechMessage } from 'frontend/assets/icons';
import { List, Panel } from 'frontend/components';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { setInsightsPanelMenuMinimized } from 'frontend/state/dux/insights';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import styles from './PanelMenu.scss';

interface MenuItem {
  title: string;
  key: string;
  icon: React.ReactElement;
  to: string;
}

const MENU_ITEMS: Record<string, unknown>[] = [
  {
    title: 'Overview',
    key: 'overview',
    icon: <Dashboard />,
    to: '../insights/overview',
  },
  {
    title: 'Conversations',
    key: 'conversations',
    icon: <SpeechMessage />,
    to: '../insights/chat',
  },
];

const PanelMenu = () => {
  const { isMenuPanelMinimized } = useAppSelector(({ insights }) => insights);
  const dispatch = useAppDispatch();
  const { botId } = useParams();

  const { isKindlyAdminManager } = useMyPermissions({ botId });

  return (
    <Panel size="small" className={styles.panelMenuWrapper}>
      <List
        dataSource={MENU_ITEMS}
        renderItem={(item) => {
          const menuItem = item as unknown as MenuItem;
          if (menuItem.key === 'overview' && !isKindlyAdminManager) {
            return null;
          }
          return (
            <List.NavLinkItem
              className={cx(styles.navigationElContainer, {
                [styles.minimized]: isMenuPanelMinimized,
                [styles.kindlyManager]: isKindlyAdminManager && menuItem.key === 'overview',
              })}
              key={menuItem.key}
              to={menuItem.to}
            >
              {menuItem.icon}
              {!isMenuPanelMinimized && <span>{menuItem.title}</span>}
            </List.NavLinkItem>
          );
        }}
      />
      <div>
        <List.Item
          className={cx(styles.navigationElContainer, styles.navigationContainerMinimize, {
            [styles.minimized]: isMenuPanelMinimized,
          })}
          onClick={() => {
            dispatch(setInsightsPanelMenuMinimized(!isMenuPanelMinimized));
          }}
        >
          <ChevronLeftSquare />
          {!isMenuPanelMinimized && <span>Minimize</span>}
        </List.Item>
      </div>
    </Panel>
  );
};

export default PanelMenu;
