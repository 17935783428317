import { format } from 'date-fns';

import { Button as ButtonIcon, Calendar, Channel, Chart, Email, Filter, Nudge, Timezone } from 'frontend/assets/icons';
import { Button, Icon, Panel } from 'frontend/components';
import { AnalyticsDemoButton } from 'frontend/features/Analytics/components';
import {
  useButtonTypes,
  useDateRanges,
  useFilters,
  useGranularity,
  useSource,
} from 'frontend/features/Analytics/hooks';
import type { Granularity } from 'frontend/features/Analytics/types';
import { snakeToHumanReadable } from 'frontend/utils';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './FilterInfo.scss';
import { FilterInfoLanguages, TimezonePicker } from './components';

type FilterType =
  | 'timezone'
  | 'date'
  | 'granularity'
  | 'language'
  | 'sources'
  | 'feedbacks'
  | 'nudges'
  | 'engagementCampaigns';

interface FilterInfoProps {
  includeLanguages?: boolean;

  showFilterView(): void;

  hideFilter?: Partial<Record<FilterType, boolean>>;
  filterOnButtonTypes?: boolean;
  initialGranularity?: Granularity;
}

const FilterInfo = ({
  showFilterView,
  hideFilter,
  filterOnButtonTypes,
  includeLanguages = true,
  initialGranularity = 'day',
}: FilterInfoProps) => {
  const { from, to } = useDateRanges(); // FIXME: support month as default
  const [granularity] = useGranularity(initialGranularity);
  const [sources] = useSource();
  const [buttonTypes] = useButtonTypes();
  const filters = useFilters();

  const selectedSources = sources.length === 0 ? 'Channel: All except test' : `Channel: ${sources.join(', ')}`;

  const selectedButtonTypes =
    buttonTypes.length === 0
      ? 'Button types: All'
      : `Button types: ${buttonTypes.map(snakeToHumanReadable).join(', ')}`;

  return (
    <Panel className={styles.innerContainer}>
      <Button className="m-b-4" onClick={showFilterView} color="primary" icon={Filter}>
        Filter
      </Button>
      <AnalyticsDemoButton className="m-b-4" />
      {!hideFilter?.timezone && (
        <div className="m-b-3">
          <div className="m-b-1">
            <strong>Options:</strong>
          </div>

          <div className={styles.item}>
            <Icon component={Timezone} />
            <span>
              <TimezonePicker />
            </span>
          </div>
        </div>
      )}
      <>
        <div className="m-b-1">
          <strong>Showing:</strong>
        </div>
        {!hideFilter?.date && (
          <div className={styles.item}>
            <Icon component={Calendar} />
            <span>
              {format(from, DATE_FORMAT_DATE)} - {format(to, DATE_FORMAT_DATE)}
            </span>
          </div>
        )}
        {!hideFilter?.granularity && (
          <div className={styles.item}>
            <Icon component={Chart} />
            <span>{`Data shown by ${granularity}`}</span>
          </div>
        )}
        {includeLanguages && !hideFilter?.language && <FilterInfoLanguages />}
        {!hideFilter?.sources && (
          <div className={styles.item}>
            <Icon component={Channel} />
            <span>{selectedSources}</span>
          </div>
        )}
        {filterOnButtonTypes && (
          <div className={styles.item}>
            <Icon component={ButtonIcon} />
            <span>{selectedButtonTypes}</span>
          </div>
        )}
        {!hideFilter?.nudges && !!filters.nudge_id && (
          <div className={styles.item}>
            <Icon component={Nudge} />
            <span>{`Nudge campaign: ${filters.nudge_id}`}</span>
          </div>
        )}
        {!hideFilter?.engagementCampaigns && !!filters.campaign_id && (
          <div className={styles.item}>
            <Icon component={Email} />
            <span>{`Email campaign: ${filters.campaign_id}`}</span>
          </div>
        )}
      </>
    </Panel>
  );
};

export default FilterInfo;
