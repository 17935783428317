import { isEmpty } from 'lodash';

import type { AttachmentType, ButtonType, FormSubmissionType, MessageReferencesType } from 'frontend/api/generated';
import type { CHAT_MESSAGE_BUTTON_TYPES, CHAT_MESSAGE_SENDER_TYPES } from 'frontend/features/Inbox/types';
import type { CHAT_SOURCE } from 'frontend/types/chat';

import styles from './ChatMessageLogItem.scss';
import sharedStyles from '../../shared.scss';
import Attachment from '../Attachment';
import ChatButton from '../ChatButton';
import ChatLogForm from '../ChatLogForm/ChatLogForm';
import ChatMessage from '../ChatMessage';
import ImageCarousel from '../ImageCarousel';
import type { CorrectedImageType } from '../ImageCarousel/ImageCarousel';

const belongsToMessage =
  (attachmentIds) =>
  ({ id, temporaryId }) =>
    attachmentIds?.find((attachmentId) => attachmentId === id || attachmentId === temporaryId);

interface ChatMessageLogItem {
  botId: string;
  message: string;
  messageReferences: Omit<MessageReferencesType, 'Id'>[];
  messageWithKindlyEntities?: string;
  buttons: {
    id: 'string';
    label: 'string';
    button_type: CHAT_MESSAGE_BUTTON_TYPES;
    contact_details: {
      edit_text: string;
    };
    title: string;
    isButtonClick: boolean;
  }[];
  image?: string;
  imageCarousel: CorrectedImageType[];
  imageCarouselSize: number;
  sender: CHAT_MESSAGE_SENDER_TYPES;
  suggestions: {
    id: string;
    preface: string;
    message: string;
    buttons: ButtonType[];
  }[];
  scrollToBottom: () => void;
  chatSource?: CHAT_SOURCE;
  legacyAttachments?: AttachmentType[];
  attachments?: AttachmentType[];
  form: string;
  formSubmission?: FormSubmissionType;
  attachmentIds: string[];
}

const ChatMessageLogItem = ({
  botId,
  message,
  messageReferences,
  messageWithKindlyEntities,
  buttons,
  image,
  imageCarousel,
  imageCarouselSize,
  sender,
  suggestions,
  scrollToBottom,
  chatSource,
  legacyAttachments = [],
  attachments = [],
  form,
  formSubmission,
  attachmentIds = [],
}: ChatMessageLogItem) => {
  const allAttachments = [...legacyAttachments, ...attachments.filter(belongsToMessage(attachmentIds) as () => void)];
  const parsedForm = typeof form === 'string' ? JSON.parse(form) : form;
  return (
    <>
      <ChatMessage
        botId={botId}
        message={message}
        messageReferences={messageReferences}
        attachments={allAttachments}
        messageWithKindlyEntities={messageWithKindlyEntities}
        sender={sender}
        chatSource={chatSource}
      />
      {!!image && <img className={styles.chatMessageImage} src={image} alt="chat message" />}
      {(!parsedForm || isEmpty(parsedForm)) && allAttachments.length > 0 && (
        <div className={styles.attachements}>
          {allAttachments.map((attachment) => (
            <Attachment key={attachment.id} attachment={attachment} scrollToBottom={scrollToBottom} />
          ))}
        </div>
      )}
      {imageCarousel?.length > 0 && (
        <div className={styles.imageCarousel}>
          <ImageCarousel imageCarousel={imageCarousel} size={imageCarouselSize} />
        </div>
      )}
      {Boolean(parsedForm) && <ChatLogForm form={form} formSubmission={formSubmission} attachments={allAttachments} />}
      {buttons?.length > 0 && (
        <div className={styles.chatButtonWrapper}>
          {buttons.map((button) => (
            <ChatButton
              key={button.id}
              label={button.label}
              buttonType={button.button_type}
              contactDetails={button.contact_details}
              title={button.title}
              isButtonClick={button.isButtonClick}
            />
          ))}
        </div>
      )}
      {suggestions?.length > 0 && (
        <div className={sharedStyles.chatMessage}>
          {suggestions.map((suggestion) => (
            <div key={suggestion.id}>
              <strong>{suggestion.preface}</strong>
              {` ${suggestion.message}`}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ChatMessageLogItem;
