import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Field } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Calendar, Chart, Email, Nudge, Trigger } from 'frontend/assets/icons';
import { Checkbox, DatePicker, FilterAccordion, FilterBackButton, FilterSideBar } from 'frontend/components';
import { GranularityPickerForm, NudgePickerForm, SessionTypePickerForm } from 'frontend/features/Analytics/components';
import CampaignPickerForm from 'frontend/features/Analytics/components/CampaignPickerForm';
import { HideFilterType } from 'frontend/features/Analytics/propTypes';
import PlatformDataItems from 'frontend/features/Analytics/views/Filters/components/FilterItems/components/PlatformDataItems';
import { BUTTON_TYPES, BUTTON_TYPE_NAMES, NON_STANDARD_BUTTON_TYPES } from 'frontend/features/Build/constants';
import { NavigationButton } from 'frontend/features/Inbox/components';
import { useKeyDown } from 'frontend/hooks';
import { DateType } from 'frontend/propTypes';
import { filterObject } from 'frontend/utils';

import styles from './FilterItems.scss';
import { BotDataItems } from './components';

const INCLUDED_BUTTON_TYPES = filterObject((key) => !NON_STANDARD_BUTTON_TYPES.includes(key), BUTTON_TYPES);

const FilterItems = ({
  handleSubmit,
  hideFilterView,
  filterViewShowing,
  hideFilter,
  filterOnButtonTypes,
  platformAnalytics,
  filters: { before, after, minDate, maxDate },
}) => {
  const hasDatePicker = !hideFilter?.date;
  const navigate = useNavigate();
  const location = useLocation();
  const enter = useKeyDown({ action: hideFilterView });
  const clearFilters = useCallback(() => {
    hideFilterView();
    navigate(location.pathname);
  }, [navigate, hideFilterView, location]);

  return (
    <>
      <FilterSideBar>
        <FilterSideBar.Header>
          <FilterBackButton handleSubmit={handleSubmit} />
          <NavigationButton onClick={clearFilters} text="Reset filters" />
        </FilterSideBar.Header>
        <FilterSideBar.Content>
          {!hideFilter?.nudges && (
            <FilterAccordion title="Nudge" icon={Nudge}>
              <NudgePickerForm className="m-b-3" />
            </FilterAccordion>
          )}
          {!hideFilter?.engagementCampaigns && (
            <FilterAccordion title="Campaign" icon={Email}>
              <CampaignPickerForm className="m-b-3" />
            </FilterAccordion>
          )}
          {!hideFilter?.sessionTypes && (
            <FilterAccordion title="Session type" icon={Chart}>
              <SessionTypePickerForm className="m-b-3" />
            </FilterAccordion>
          )}
          <FilterAccordion title="Date" icon={Calendar}>
            {!hideFilter?.granularity && <GranularityPickerForm className="m-b-3" />}
            {hasDatePicker && (
              <Field
                /* eslint-disable-next-line react/no-unstable-nested-components */
                component={({ input: { name, onChange } }) => (
                  <DatePicker
                    showSelect
                    minDate={minDate}
                    maxDate={maxDate}
                    initialEndDate={before}
                    initialStartDate={after}
                    name={name}
                    onChange={onChange}
                  />
                )}
                name="datePicker"
              />
            )}
          </FilterAccordion>
          {!hideFilter?.platformValues &&
            (platformAnalytics ? (
              <PlatformDataItems hideFilter={hideFilter} />
            ) : (
              <BotDataItems hideFilter={hideFilter} />
            ))}
          {filterOnButtonTypes && (
            <FilterAccordion title="Button Types" icon={Trigger}>
              {Object.entries(INCLUDED_BUTTON_TYPES).map(([key, value]) => (
                <Field
                  key={`button-type-${key}`}
                  name={`buttonTypes.${value}`}
                  className={styles.filterListItem}
                  component={Checkbox}
                  type="checkbox"
                  label={BUTTON_TYPE_NAMES[value]}
                />
              ))}
            </FilterAccordion>
          )}
        </FilterSideBar.Content>
      </FilterSideBar>
      <div
        className={cx(styles.backdrop, {
          [styles.backdropVisible]: filterViewShowing,
        })}
        role="button"
        tabIndex={0}
        onKeyDown={enter}
        onClick={hideFilterView}
      />
    </>
  );
};

FilterItems.propTypes = {
  platformAnalytics: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideFilterView: PropTypes.func.isRequired,
  filterViewShowing: PropTypes.bool.isRequired,
  hideFilter: HideFilterType,
  filters: PropTypes.shape({
    before: PropTypes.oneOfType([DateType, PropTypes.string]),
    after: PropTypes.oneOfType([DateType, PropTypes.string]),
    minDate: PropTypes.oneOfType([DateType, PropTypes.string]),
    maxDate: PropTypes.oneOfType([DateType, PropTypes.string]),
  }),
  filterOnButtonTypes: PropTypes.bool,
};

export default FilterItems;
