import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useCallback, useMemo, useReducer } from 'react';

import { type ButtonOrderingEnum, type ButtonTypeEnum, ButtonsOverviewDocument } from 'frontend/api/generated';
import { Filter } from 'frontend/assets/icons';
import { Button, Dropdown, Input, Table } from 'frontend/components';
import { useBotOrSkill, useCurrentLanguage, useDebounce } from 'frontend/hooks';

import { COLUMN_FIELDS, MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS, MAP_BUTTON_TYPE } from './constants';
import { DASHBOARD_VIEW } from '../../constants';
import { useButtonsRenderMap } from '../../hooks';
import { compileButtonList } from '../../utils';
import EmptyState from '../EmptyState';
import { buttonsReducer, initialState } from './ButtonsReducer';
import FilterOptions from './FilterOptions';
import styles from '../BuildResources/BuildResources.scss';
import { useColumns } from '../BuildResources/hooks';

export const PAGE_SIZE = 10;

const ORDERED_COLUMNS = COLUMN_FIELDS.filter((field) => !['dialogue', 'actions'].includes(field));

function ButtonsOverview() {
  const { buildIdObject, buildType } = useBotOrSkill();
  const [{ selectedLanguage }] = useCurrentLanguage();
  const [state, dispatch] = useReducer(buttonsReducer, initialState);

  const debouncedSearchQuery = useDebounce(state.search, 300);

  const {
    data,
    loading,
    refetch: refetchButtonsOverview,
  } = useQuery(ButtonsOverviewDocument, {
    variables: {
      botId: buildIdObject!.botId!,
      languageCode: selectedLanguage,
      page: state.page,
      pageSize: PAGE_SIZE,
      search: debouncedSearchQuery,
      buttonTypes: (() => {
        const buttonTypesFilter = Object.entries(state.buttonTypes)
          .filter(([key, value]) => value && key in MAP_BUTTON_TYPE)
          .map(([key]) => MAP_BUTTON_TYPE[key])
          .filter((type): type is ButtonTypeEnum => type !== undefined);
        return buttonTypesFilter.length > 0 ? buttonTypesFilter : undefined;
      })(),
      orderBy: state.descending
        ? (`${MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS[state.orderBy]}_DESC` as ButtonOrderingEnum)
        : (MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS[state.orderBy] as ButtonOrderingEnum),
    },
    fetchPolicy: 'no-cache',
    skip: !selectedLanguage,
  });

  const pagination = {
    currentPage: data?.buttons?.page || 0,
    pages: data?.buttons?.pages || 1,
    setPage: useCallback((page: number) => dispatch({ type: 'SET_PAGE', payload: page }), []),
    summary: {
      totalCount: data?.buttons?.count || 0,
      firstVisible: data?.buttons?.startIndex || 0,
      lastVisible: data?.buttons?.endIndex || 0,
    },
  };

  const buttons = data?.buttons?.buttons;
  const buttonList = useMemo(() => {
    if (!buttons) {
      return [];
    }
    return compileButtonList(selectedLanguage, buttons);
  }, [selectedLanguage, buttons]);

  const renderMap = useButtonsRenderMap({
    buildIdObject: buildIdObject!,
    buildType: buildType!,
    refetchButtonsOverview,
    selectedLanguage,
  });

  const showNoData = !loading && (!buttons || buttons.length === 0);

  const columns = useColumns({
    columnFields: [...COLUMN_FIELDS],
    orderByField: state.orderBy,
    setOrderByField: useCallback((field: string) => {
      // Typing is annoying sometimes
      if (field in MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS) {
        dispatch({ type: 'SET_ORDER_BY', payload: field as keyof typeof MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS });
      }
    }, []),
    descending: state.descending,
    setDescending: useCallback((descending: boolean) => dispatch({ type: 'SET_DESCENDING', payload: descending }), []),
    orderedColumns: ORDERED_COLUMNS,
    renderMap,
  });

  const onSetFilters = useCallback((newButtonTypes: Record<string, boolean>) => {
    dispatch({ type: 'SET_BUTTON_TYPES', payload: newButtonTypes });
  }, []);

  return (
    <>
      <div className={cx(styles.filterWrapper, 'm-t-3')}>
        <Input
          input={{
            name: 'search',
            value: state.search,
            onChange: (e) => dispatch({ type: 'SET_SEARCH', payload: e.target.value }),
          }}
          placeholder="Search"
          className={styles.searchInput}
        />
        <Dropdown element="div" overlay={<FilterOptions buttonTypes={state.buttonTypes} setFilters={onSetFilters} />}>
          <Button icon={Filter}>Filter</Button>
        </Dropdown>
      </div>
      {showNoData ? (
        <EmptyState
          className="m-t-7"
          headLine={`No buttons found in this ${buildType} for this language and filters.`}
          text={`You can use this page to get an overview of buttons in the ${buildType}, after you have created some dialogues with buttons.`}
          dashboardView={DASHBOARD_VIEW.BUTTONS}
        />
      ) : (
        <Table data={buttonList} columns={columns} loading={loading} pagination={pagination} />
      )}
    </>
  );
}

export default ButtonsOverview;
