import React from 'react';

import { Checkbox, Radio } from 'frontend/components';
import { BUTTON_TYPE_NAMES } from 'frontend/features/Build/constants';

import styles from './ButtonsFilter.scss';

interface FilterOptionsProps {
  buttonTypes: Record<string, boolean>;
  setFilters: (newButtonTypes: Record<string, boolean>) => void;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ buttonTypes, setFilters }) => {
  const allChecked = Object.values(buttonTypes).every((value) => value);
  const handleChange = (key: string) => {
    if (allChecked) {
      setFilters(Object.fromEntries(Object.entries(buttonTypes).map(([k, _]) => [k, k === key])));
    } else {
      setFilters({ ...buttonTypes, [key]: !buttonTypes[key] });
    }
  };
  return (
    <div className={styles.wrapper}>
      <Radio
        input={{
          name: 'all',
          checked: allChecked,
          onChange: () => {
            setFilters(Object.fromEntries(Object.entries(buttonTypes).map(([key, _]) => [key, !allChecked])));
          },
        }}
        label="All"
        className={styles.radio}
      />
      {Object.entries(buttonTypes).map(([key, value]) => (
        <Checkbox
          key={key}
          input={{
            name: key,
            checked: allChecked ? false : value,
            onChange: () => handleChange(key),
          }}
          className={styles.checkbox}
          label={BUTTON_TYPE_NAMES[key]}
        />
      ))}
    </div>
  );
};

export default FilterOptions;
