import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

export const ChatFeedbackType = PropTypes.shape({
  id: IDType.isRequired,
  created: PropTypes.string.isRequired,
  feedbackType: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  freeformText: PropTypes.string,
});

export const HideFilterType = PropTypes.shape({
  language: PropTypes.bool,
  date: PropTypes.bool,
  granularity: PropTypes.bool,
  nudges: PropTypes.bool,
});
