import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Gift, Info } from 'frontend/assets/icons';
import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import { Dropdown, Icon } from 'frontend/components';
import { Notifications } from 'frontend/features';
import { PlatformAlert } from 'frontend/features/Admin/views';
import { useMixpanel } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { logout } from 'frontend/state/dux/auth';
import { getUrlParameter } from 'frontend/utils';

import styles from './Navbar.scss';
import {
  BotMenu,
  HomeMenu,
  InfoMenu,
  NavbarDropdown,
  NavbarStaticLogo,
  OrganizationMenu,
  SkillMenu,
  UserMenu,
} from './components';

function Navbar() {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { pathname } = useLocation();
  const onSignOut = useCallback(() => dispatch(logout(client)), [client, dispatch]);
  const { mixpanel } = useMixpanel();
  const { loading: userLoading, data: userData } = useMe();
  const botId = getUrlParameter('workspace', pathname);
  const skillId = getUrlParameter('skill', pathname);
  const organizationId = getUrlParameter('organization', pathname);
  const rootView = !(botId || skillId || Boolean(organizationId));

  useEffect(() => {
    if (window.env.CANNY_APP_ID) {
      // @ts-expect-error: global Canny
      Canny('initChangelog', {
        appID: window.env.CANNY_APP_ID,
        position: 'bottom',
        align: 'right',
      });
    }
  }, []);

  useEffect(
    () => () => {
      // @ts-expect-error: global Canny
      Canny('closeChangelog');
    },
    [],
  );

  const userName = userData?.me?.profile?.fullName ?? '';
  const userAvatar = userData?.me?.profile?.avatarUrl ?? defaultAvatar;

  return (
    <nav className={styles.navbarWrapper}>
      <div className={styles.navbar}>
        {rootView ? <NavbarStaticLogo /> : <NavbarDropdown />}
        <div className={styles.navigation} role="navigation">
          <Routes>
            <Route path="/workspace/:botId/*" element={<BotMenu />} />
            <Route path="/skill/:skillId/*" element={<SkillMenu />} />
            <Route path="/organization/:organizationId/*" element={<OrganizationMenu />} />
            <Route path="/*" element={<HomeMenu />} />
          </Routes>
        </div>
        <div className={styles.menu}>
          <PlatformAlert />
          <Notifications />
          <button
            type="button"
            className={styles.changelog}
            data-canny-changelog
            aria-label="Open Kindly changelog"
            onClick={() => {
              mixpanel.track('Open changelog');
            }}
          >
            <Icon component={Gift} title="Kindly changelog" className={styles.giftIcon} />
          </button>
          <Dropdown
            overlayClassName={styles.dropdownSettings}
            wrapperClassName={styles.infoDropdown}
            style={{ marginRight: '10px', marginTop: '5px', zIndex: 200 }}
            overlay={<InfoMenu />}
            title="Info menu"
          >
            <span className={styles.infoIcon}>
              <Icon component={Info} title="Info menu" />
            </span>
          </Dropdown>
          {!userLoading && (
            <Dropdown
              overlayClassName={styles.dropdownSettings}
              wrapperClassName={styles.userProfileDropdown}
              style={{ marginRight: '8px', marginTop: '0px', zIndex: 200 }}
              overlay={
                <UserMenu
                  onSignOut={onSignOut}
                  userName={userName}
                  userAvatar={userAvatar}
                  favoriteBots={userData?.me.profile.favoriteBots ?? []}
                />
              }
              title="User menu"
            >
              <img className={styles.avatar} src={userAvatar} alt={userName} />
            </Dropdown>
          )}
        </div>
      </div>
      <div id="submenu-target" />
    </nav>
  );
}

export default Navbar;
