import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentBreadcrumbs, setCurrentBreadcrumbs } from 'frontend/state/dux/build';

export default function useUpdateCurrentBreadcrumbs(dialogue) {
  const dispatch = useDispatch();
  const currentBreadcrumbs = useSelector(selectCurrentBreadcrumbs);
  const breadcrumbs = dialogue?.breadcrumbs;

  useEffect(() => {
    // Cannot use a check on breadcrumbs.length because lowest level dialogues have breadcrumbs []
    if (!isEqual(currentBreadcrumbs, breadcrumbs)) {
      dispatch(setCurrentBreadcrumbs(breadcrumbs));
    }
  }, [breadcrumbs, currentBreadcrumbs, dispatch]);
}
