import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { ModalProvider } from 'react-modal-hook';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { LibraryDialoguesDocument } from 'frontend/api/generated';
import { Condition, FormErrors, GuardUnsaved, NoteFormChanges, Panel } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { useBotSettings } from 'frontend/features/BotSettings/hooks';
import { usePrevious, useUrlSearchParams } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import styles from './DialogueFormContents.scss';
import sharedStyles from '../../Build.scss';
import { useDialogueOutputs, useDialogueType } from '../../hooks';
import type { BuildFormType } from '../../propTypes/BuildFormType';
import DialogueFormInput from '../DialogueFormInput';
import DialogueFormOutput from '../DialogueFormOutput';
import DialoguePageBar from '../DialoguePageBar';
import DialoguePath from '../DialoguePath';
import DialogueType from '../DialogueType';
import EditingNow from '../EditingNow';
import FlowModal from '../FlowBuilder';
import Preview from '../Preview';
import RightSidePanel from '../RightSidePanel';
import VerticalToolbar from '../VerticalToolbar/VerticalToolbar';

export interface DialogueData {
  buildFormType: BuildFormType | null;
  buildIdObject: BuildIdObject;
  currentRuleId: string | undefined;
  dialogueId: string | undefined;
  initialValues: Record<string, any>;
  isModDialogue?: boolean;
  parentId?: string | undefined;
  selectedLanguage: string;
  intent?: string;
}

interface Props {
  allowRedirect?: boolean;
  buildIdObject: BuildIdObject;
  selectedLanguage: string;
  currentRuleId: string | undefined;
  hasChanges: boolean;
  isCreateForm: boolean;
  isModDialogue?: boolean;
  parentId?: string | undefined;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const DialogueFormContents = ({
  allowRedirect,
  buildIdObject,
  selectedLanguage,
  currentRuleId,
  hasChanges,
  isCreateForm,
  isModDialogue,
  parentId,
  setHasChanges,
}: Props) => {
  const [shouldShowRightSidePanel, setShouldShowRightSidePanel] = useState(false);

  const { initialValues, values } = useFormState();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { botId, skillId, dialogueId } = useParams<{ botId?: string; skillId?: string; dialogueId: string }>();
  const { botSettings } = useBotSettings(botId);
  const shouldShowSmartReplies = Boolean(botSettings?.smartRepliesEnabled);

  const buildFormType = useDialogueType();
  const { tabsShown, setTabsToShowExtra } = useDialogueOutputs({
    isModDialogue,
    buildFormType,
    shouldShowSmartReplies,
  });

  const { id, mod, dialogueType, isActive, modIsActive, plugininstance, colorLabel, subscribedSkillName, intent } =
    initialValues;
  const shouldShowFlowBuilder = searchParams.get('view') === 'flow';
  const shouldShowOutputPanel = searchParams.get('formContent') === 'output';
  const isNewDialogue = pathname.includes('new');

  const toggleRightSidePanel = () => setShouldShowRightSidePanel((prev) => !prev);

  const { data: dialogues, loading } = useQuery(LibraryDialoguesDocument, {
    variables: { botId, skillId, parentId: dialogueId, regular: true, endToEnd: true },
  });

  const dialogueData: DialogueData = {
    buildFormType,
    buildIdObject,
    selectedLanguage,
    currentRuleId,
    dialogueId,
    initialValues,
    isModDialogue,
    parentId,
    intent,
  };

  const dialogueLabelColorData = {
    colorLabel: mod?.modColorLabel ?? colorLabel,
    dialogueType,
  };

  const followUpCount = dialogues && dialogues?.dialogues.length;

  const [, , unsetParams] = useUrlSearchParams();

  useEffect(() => {
    const rules = values.dialogueRules || [];
    if (dialogueId !== values.id) {
      return;
    }

    if (
      currentRuleId &&
      rules.length &&
      !rules?.some(({ id: ruleId, languageCode }) => ruleId === currentRuleId && languageCode === selectedLanguage)
    ) {
      unsetParams(['rule'], { replace: true });
    } else if (currentRuleId && !rules.length) {
      unsetParams(['rule'], { replace: true });
    }
  }, [currentRuleId, unsetParams, values.dialogueRules, values.id, selectedLanguage, dialogueId]);

  // Reset tabsToShowExtra when switching between rules
  const previousRuleId = usePrevious(currentRuleId);
  useEffect(() => {
    if (previousRuleId !== currentRuleId) {
      setTabsToShowExtra([]);
    }
  }, [previousRuleId, currentRuleId, setTabsToShowExtra]);

  return (
    <ModalProvider>
      {/* ModalProvider needs to be inside the form provider (from react-final-form) if we want to access the form from within a modal */}
      <NoteFormChanges hasChanges={hasChanges} setHasChanges={setHasChanges} notifyBuild />
      <GuardUnsaved hasChanges={hasChanges} allowRedirect={allowRedirect} allowSamePathRedirect />
      {shouldShowFlowBuilder && (
        <FlowModal
          dialogueLabelColorData={dialogueLabelColorData}
          dialogues={dialogues}
          isActive={modIsActive || isActive}
          loading={loading}
        />
      )}
      <div className={sharedStyles.dialogueFormWrapper} data-testid="dialogueFormWrapper">
        {!isCreateForm && <EditingNow dialogueLikeId={id} />}

        <Panel className={styles.pageContent}>
          <FormErrors />

          <Condition when="dialogueType" is="">
            <div className={styles.newDialogue}>
              <h3 className="m-b-xl">New dialogue</h3>
              <div className={styles.newDialogueButtons}>
                <Field name="dialogueType" component={DialogueType} />
              </div>
              <p className="m-t-md">
                {`Choose whether your new dialogue will be based on ${
                  botSettings?.intentMatchEnabled && !searchParams.get('dialogueModParentId') ? 'intent' : 'samples'
                }, keywords, patterns, or triggered by API.`}
              </p>
            </div>
          </Condition>

          <Condition when="dialogueType" isAny={Object.values(DIALOGUE_TYPES)}>
            <div className={styles.pageHeader}>
              <DialoguePath
                dialogueLabelColorData={dialogueLabelColorData}
                isActive={modIsActive || isActive}
                readOnly={Boolean(isModDialogue)}
                subscribedSkillName={subscribedSkillName}
              />
              <DialoguePageBar hasChanges={hasChanges} followUpCount={followUpCount} isNewDialogue={isNewDialogue} />
            </div>
            <div className={styles.tabsContainer} id="dialogue-form-contents">
              <VerticalToolbar
                isSkill={Boolean(skillId)}
                toggleRightSidePanel={toggleRightSidePanel}
                isRightSidePanelShown={shouldShowRightSidePanel}
                isOutputPanelShown={shouldShowOutputPanel}
              />
              <div className={styles.verticalSeparator} />
              <Preview
                isModDialogue={Boolean(isModDialogue)}
                plugin={plugininstance}
                tabsShownMap={tabsShown}
                setTabsToShowExtra={setTabsToShowExtra}
                shouldShowSmartReplies={shouldShowSmartReplies}
              />
              <div className={styles.verticalSeparator} />
              <div className={styles.tabContentWrapper}>
                {shouldShowOutputPanel ? (
                  <DialogueFormOutput
                    dialogueData={dialogueData}
                    tabsShownMap={tabsShown}
                    setTabsToShowExtra={setTabsToShowExtra}
                    shouldShowSmartReplies={shouldShowSmartReplies}
                  />
                ) : (
                  <DialogueFormInput dialogueData={dialogueData} />
                )}
              </div>
            </div>
          </Condition>

          {shouldShowRightSidePanel && (
            <RightSidePanel
              heading="Labels"
              className={styles.rightSidePanel}
              toggleRightSidePanel={toggleRightSidePanel}
            />
          )}
        </Panel>
      </div>
    </ModalProvider>
  );
};

export default DialogueFormContents;
