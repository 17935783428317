import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  AnalyticsContent,
  Grid,
  HandoverDuration,
  HandoverLineChart,
  HandoverWaitTime,
  Heading,
} from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import { useBooleanState } from 'frontend/hooks';
import { PRIORITY } from 'frontend/state/dux/analytics/dataQueue';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

const HIDE_FILTERS = { nudges: true, engagementCampaigns: true, sessionTypes: true };

const Handover = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters();
  const scope = useMemo(() => sageScope(SAGE_RESOURCE.BOT, botId), [botId]);

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <div className="m-b-4">
          <Grid>
            <div>
              <Heading title="Handover duration" />
              <HandoverDuration scope={scope} filters={filters} priority={PRIORITY.LOW} />
            </div>
            <div>
              <Heading title="Handover wait time" />
              <HandoverWaitTime scope={scope} filters={filters} priority={PRIORITY.LOW} />
            </div>
          </Grid>
          <Heading
            className="m-t-0"
            title="Ended handovers"
            subtitle={`Number of ended handovers per ${filters.granularity}`}
          />
          <HandoverLineChart scope={scope} filters={filters} event={HandoverLineChart.EVENT_TYPES.ENDED} />
          <Heading
            className="m-t-4"
            title="Handover requests"
            subtitle={`Number of requested handovers per ${filters.granularity}`}
          />
          <HandoverLineChart scope={scope} filters={filters} event={HandoverLineChart.EVENT_TYPES.REQUESTS} />
          <Heading
            className="m-t-4"
            title="Cancelled requests"
            subtitle="Number of times users exited the handover queue"
          />
          <HandoverLineChart scope={scope} filters={filters} event={HandoverLineChart.EVENT_TYPES.EXITED} />
        </div>
      </AnalyticsContent>
    </>
  );
};

export default Handover;
