import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Form } from 'react-final-form';

import { FormErrors, GuardUnsaved } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';
import { formHasChanged } from 'frontend/utils';

import Modal from '../Modal';

const SUBSCRIBE = {
  values: true,
  valid: true,
  dirty: true,
  submitErrors: true,
  submitting: true,
  submitSucceeded: true,
};

const ModalForm = ({
  hide,
  onSubmit,
  title,
  children,
  validate,
  onOkText,
  render,
  color,
  guardUnsaved,
  onCancel,
  onCancelText,
  initialValuesEqual,
  updateOnSubmit,
  confirmDiscardChanges,
  ignoreDirty,
  closeOnSubmit,
  disableAnimation,
  footer,
  initialValues = {},
  mutators = {},
  showFormErrors = true,
  disabled = false,
  className = '',
}) => {
  const renderForm = useCallback(
    ({ handleSubmit, valid, dirty, submitErrors, form, values, submitting, submitSucceeded }) => {
      if (!children && !render) {
        console.error('ModalForm has to be supplied with either children or render');
        return null;
      }

      const formIsSubmitting = submitting || submitSucceeded;
      const hasChanges = formHasChanged({ values, initialValues });
      const shouldConfirmDiscard = confirmDiscardChanges && hasChanges && !formIsSubmitting;

      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Modal
            title={title}
            onOk={handleSubmit}
            hide={hide}
            valid={valid}
            disabled={disabled || (!ignoreDirty && !dirty)}
            onOkText={onOkText}
            color={color}
            onCancel={onCancel}
            confirmDiscardChanges={shouldConfirmDiscard}
            onCancelText={onCancelText}
            closeOnSubmit={closeOnSubmit}
            updateOnSubmit={updateOnSubmit}
            disableAnimation={disableAnimation}
            className={className}
            footer={footer}
          >
            {showFormErrors && <FormErrors inModal />}
            {guardUnsaved && <GuardUnsaved />}
            <Modal.Content>
              {children || render({ handleSubmit, valid, dirty, submitErrors, form, values })}
            </Modal.Content>
          </Modal>
        </form>
      );
    },
    [
      children,
      closeOnSubmit,
      color,
      confirmDiscardChanges,
      disableAnimation,
      footer,
      guardUnsaved,
      hide,
      ignoreDirty,
      initialValues,
      onCancel,
      onCancelText,
      onOkText,
      render,
      showFormErrors,
      title,
      updateOnSubmit,
      disabled,
      className,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      mutators={mutators}
      initialValuesEqual={initialValuesEqual}
      subscribe={SUBSCRIBE}
      render={renderForm}
    />
  );
};

ModalForm.propTypes = {
  render: PropTypes.func,
  initialValues: PropTypes.shape({}),
  validate: PropTypes.func,
  guardUnsaved: PropTypes.bool,
  mutators: PropTypes.shape({}),
  initialValuesEqual: PropTypes.func,
  confirmDiscardChanges: PropTypes.bool,
  ignoreDirty: PropTypes.bool,
  title: PropTypes.string,
  hide: PropTypes.func.isRequired,
  onOkText: PropTypes.string,
  onCancelText: PropTypes.string,
  color: PropTypes.string,
  closeOnSubmit: PropTypes.bool,
  updateOnSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  children: ChildrenType,
  disableAnimation: PropTypes.bool,
  footer: PropTypes.bool,
  showFormErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ModalForm;
