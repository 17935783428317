import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { DeleteBotBackupDocument, DeleteMessageBackupDocument } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { DATE_FORMAT_WITH_TIME } from 'frontend/utils/date';

interface Props {
  hide(): void;

  args: {
    backup: { createdAt: string; id: string };
    botId: string;
    type: 'BOT' | 'MESSAGE';
  };
}

export default function DeleteBackup({ hide, args: { backup, botId, type } }: Props): React.JSX.Element {
  const toast = useToast();

  const update = (cache) => {
    const fieldName = `${type.toLowerCase()}Backups`;
    cache.modify({
      fields: {
        [fieldName]: (currentBackups, { readField }) =>
          currentBackups.filter((current) => readField('id', current) !== backup.id),
      },
    });
    toast.success(`${capitalize(type)} backup was deleted`);
  };

  const [deleteBotBackup] = useMutation(DeleteBotBackupDocument, {
    variables: { botId: botId!, id: backup.id },
    update,
  });
  const [deleteMessageBackup] = useMutation(DeleteMessageBackupDocument, {
    variables: { botId, id: backup.id },
    update,
  });

  return (
    <DeleteModal
      name={`backup from ${format(backup.createdAt, DATE_FORMAT_WITH_TIME)}`}
      titleName="backup"
      hide={hide}
      onSubmit={type === 'BOT' ? deleteBotBackup : deleteMessageBackup}
    />
  );
}
