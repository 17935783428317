import cx from 'classnames';
import { forwardRef, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Exclamation } from 'frontend/assets/icons';
import { Button, Icon } from 'frontend/components';
import type { ButtonColor } from 'frontend/components/Button/Button';
import { useClickOutside, useToggle } from 'frontend/hooks';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import styles from './Modal.scss';

const AnimatedBody = forwardRef<
  HTMLDivElement,
  {
    onFocus: () => void;
    onBlur: () => void;
    className: string;
    children: React.ReactNode;
  }
>(({ children, className, onFocus, onBlur }, ref) => (
  <div
    role="dialog"
    onFocus={onFocus}
    onBlur={onBlur}
    key="animated-body"
    ref={ref}
    className={cx(styles.animatedBoyWrapper, styles.fadeIn, className)}
  >
    {children}
  </div>
));

AnimatedBody.displayName = 'AnimatedBody';

type SubmitErrors = Record<string, unknown>;

interface ModalProps {
  hide: () => Promise<void> | void;
  children?: React.ReactNode;
  className?: string;
  /** Close modal on ok. @default true */
  closeOnSubmit?: boolean;
  color?: ButtonColor;
  confirmDiscardChanges?: boolean;
  /** If true, disable the ok button. */
  disabled?: boolean;
  footer?: boolean;
  /** If true, hide the cancel button. */
  hideCancel?: boolean;
  /** Icon on the left of the modal `title`. */
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /** Callback to run when cancel button is triggered. */
  onCancel?: () => void;
  /** Text for the cancel button. */
  onCancelText?: string;
  /** Callback to run when ok button is triggered. Returned data is treated as an error. */
  onOk?: (...args: unknown[]) => Promise<SubmitErrors | void> | void;
  /** Text for the ok button. */
  onOkText?: string;
  /** Modal heading. */
  title?: string;
  updateOnSubmit?: boolean;
  valid?: boolean;
  footerClassName?: string;
  headerClassName?: string;
}

const Modal = ({
  hide,
  children,
  className,
  closeOnSubmit = true,
  color = 'primary',
  confirmDiscardChanges = false,
  disabled = false,
  footer = true,
  hideCancel = false,
  icon,
  onCancel,
  onCancelText = 'Cancel',
  onOk = async () => undefined,
  onOkText = 'Ok',
  title,
  updateOnSubmit = true,
  valid = true,
  footerClassName,
  headerClassName,
}: ModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enableEnterKey, setEnableEnterKey] = useState(true);
  const [hasConfirmedDiscard, confirmDiscard] = useToggle();
  const modalRef = useRef<HTMLDivElement>(null);

  const submitDisabled = isSubmitting || disabled;
  const shouldConfirmDiscard = confirmDiscardChanges && !hasConfirmedDiscard;
  const textOnCancelWithDiscard = shouldConfirmDiscard ? 'Discard changes' : onCancelText;

  const handleCancel = () => {
    if (shouldConfirmDiscard) {
      confirmDiscard();
      return;
    }

    const shouldHide = onCancel ? onCancel() : true;
    if (shouldHide) hide();
  };

  const onSubmit = async (...args) => {
    if (valid) setIsSubmitting(true);

    try {
      // Treat everything that is returned from onOk as an error
      const submitErrors = await onOk(...args);
      if (valid && closeOnSubmit && !submitErrors) hide();
    } finally {
      if (updateOnSubmit) setIsSubmitting(false);
    }
  };

  useHotkeys('esc', handleCancel, [confirmDiscard, hide, onCancel, shouldConfirmDiscard], {
    enableOnFormTags: true,
  });
  useHotkeys('enter', onSubmit as () => void, { enabled: footer && enableEnterKey && !submitDisabled }, [
    closeOnSubmit,
    hide,
    onOk,
    updateOnSubmit,
    valid,
  ]);

  useClickOutside(modalRef, handleCancel, 'mousedown');

  const headerClassNames = cx(styles.header, headerClassName);
  const footerClassNames = cx(styles.footer, styles.footerBase, footerClassName);

  return (
    <div
      className={cx(styles.wrapper, { [styles.warningMode]: color === 'warning' })}
      tabIndex={-1}
      role="dialog"
      data-testid="rules-modal"
      ref={(ref) => {
        ref?.focus();
        ref?.removeAttribute('tabIndex');
      }}
    >
      <AnimatedBody
        onFocus={() => setEnableEnterKey(false)}
        onBlur={() => setEnableEnterKey(true)}
        className={cx(styles.body, className)}
        ref={modalRef}
      >
        {!!title && (
          <div className={headerClassNames}>
            <span className={styles.headerTitleWrapper}>
              {(color === 'warning' && <Icon component={Exclamation} />) || (icon && <Icon component={icon} />)}
              {title}
            </span>
          </div>
        )}

        {children}

        {footer && (
          <div className={footerClassNames}>
            <div className={styles.footerShortcuts}>
              <span className={styles.footerKey}>ESC</span>
              <span>
                {' '}
                <em>{textOnCancelWithDiscard}</em>
              </span>
            </div>

            {enableEnterKey && !submitDisabled && (
              <div className={cx(styles.footerShortcuts, styles.footerShortcutsEnter)}>
                <span className={cx(styles.footerKey, styles.footerEnterKey)}>⏎</span>
                <span>
                  {' '}
                  <em>{onOkText}</em>
                </span>
              </div>
            )}

            <div className={styles.footerButtons}>
              {!hideCancel && <Button onClick={handleCancel}>{textOnCancelWithDiscard}</Button>}

              <Button
                type="submit"
                onClick={onSubmit}
                color={valid ? color || 'primary' : 'secondary'}
                disabled={!isSubmitting && submitDisabled}
                isSubmitting={isSubmitting}
                text={onOkText}
              />
            </div>
          </div>
        )}
      </AnimatedBody>
    </div>
  );
};

Modal.Content = Content;
Modal.Footer = Footer;
Modal.Header = Header;

export default Modal;
