import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import invalidateCacheDialogues from 'frontend/api/cacheHelpers/invalidateCacheDialogues';
import { DropTopicInTopicDocument, LibraryDialoguesDocument } from 'frontend/api/generated';
import { Checklist, Folder, FolderPerson, FolderPlus, KindlyBlob, Trash } from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES, dragAndDropTypes } from 'frontend/constants';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill, useToast } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import { dropDialogue, dropTopic } from './utils';
import { CreateTopic, DeleteTopic, TransferTopic, UpdateTopic } from '../../modals';
import { dropDialogueInTopic } from '../../mutations';
import { getBuildUrl } from '../../utils';

export const useDropInTopic = ({ setShowLoader, buildIdObject, id, name, currentLanguage }) => {
  const client = useApolloClient();
  const [dialogueInTopic] = useMutation<any, { sourceDialogueId: string }>(dropDialogueInTopic, {
    update(cache, _, { variables }) {
      const dialogueId = variables?.sourceDialogueId;

      /* We invalidate the cache for the queries related to the connected dialogues of the moved dialogue (at starting point). */
      const connectedDialoguesAtStart: { dialogues: { id: string }[] } | null = cache.readQuery({
        query: LibraryDialoguesDocument,
        variables: { ...buildIdObject, parentId: dialogueId, regular: true, endToEnd: true },
      });
      const connectedDialoguesIdsAtStart = connectedDialoguesAtStart?.dialogues.map(({ id: dialId }) => dialId) || [];
      invalidateCacheDialogues(connectedDialoguesIdsAtStart, buildIdObject, cache);

      cache.gc();
    },
  });
  const [topicInTopic] = useMutation(DropTopicInTopicDocument);
  const toast = useToast();

  const drop = useCallback(
    async (item) => {
      setShowLoader(true);
      try {
        if (item.type === dragAndDropTypes.DIALOGUE) {
          await dropDialogue({ item, buildIdObject, client, id, dialogueInTopic, toast, name, currentLanguage });
        } else if (item.type === dragAndDropTypes.TOPIC) {
          await dropTopic({ topicInTopic, id, item, client, buildIdObject, toast, name });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [setShowLoader, buildIdObject, client, id, dialogueInTopic, toast, currentLanguage, name, topicInTopic],
  );

  return drop;
};

export const useTopicContextMenu = ({
  topic,
  botOrSkillParams,
  botOrSkillParams: { buildIdObject },
}): {
  actions: MenuOverlayOptions;
  contextMenuId: string;
} => {
  const navigate = useNavigate();
  const { buildType, buildId, botId } = useBotOrSkill();
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });
  const [showTransferTopicModal] = useModal(TransferTopic);
  const [showCreateTopic] = useModal(CreateTopic);
  const [showUpdateTopic] = useModal(UpdateTopic);
  const [showDeleteTopic] = useModal(DeleteTopic);

  const canManageEvaluation = hasBotPerms('manage_evaluation');

  const showEvaluation = 'botId' in buildIdObject && !loadingPermissions && canManageEvaluation;

  const actions = useMemo(() => {
    const target = `new?topicId=${topic.id}`;
    const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target });

    const actionsArray: MenuOverlayOptions = [
      {
        text: 'New dialogue',
        icon: KindlyBlob,
        onClick: () => navigate(newDialogueUrl),
      },
      {
        text: 'New folder',
        icon: FolderPlus,
        onClick: () => showCreateTopic({ parentTopicId: topic.id, buildIdObject }),
      },
      'separator',
      { text: 'Edit folder', icon: Folder, onClick: () => showUpdateTopic({ topic, buildIdObject }) },
      {
        text: 'Transfer folder',
        icon: FolderPerson,
        onClick: () => showTransferTopicModal({ topic, botOrSkillParams }),
      },
      'separator',
      { text: 'Delete folder', icon: Trash, onClick: () => showDeleteTopic({ topic, botOrSkillParams }), color: 'red' },
    ];

    if (showEvaluation) {
      const url = `/workspace/${buildIdObject.botId}/build/evaluation?folderId=${topic.id}`;
      actionsArray.splice(3, 0, { text: 'Evaluate folder', icon: Checklist, onClick: () => navigate(url) });
      actionsArray.splice(4, 0, 'separator');
    }

    return actionsArray;
  }, [
    topic,
    botOrSkillParams,
    buildType,
    buildId,
    showEvaluation,
    navigate,
    showCreateTopic,
    showDeleteTopic,
    showUpdateTopic,
    showTransferTopicModal,
    buildIdObject,
  ]);

  return { actions, contextMenuId: `library-context-menu` };
};
