import { useEffect, useState } from 'react';

/**
 * This hook works exactly like `useState`, but it also persists the state in `localStorage`.
 * @param key - The key to store the state in `localStorage`.
 * @param initialValue - The initial value to use if the key is not found in `localStorage`.
 * @returns A tuple containing the current state and a function to update it.
 */
export default function useLocalStorageState<T>(key: string, initialValue: T): [T, (value: T) => void] {
  // Get initial state from localStorage or use initialValue
  const [state, setState] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  // Update localStorage when state changes
  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.error(`Error writing to localStorage key "${key}":`, error);
    }
  }, [key, state]);

  return [state, setState];
}
