import { $isLinkNode } from '@lexical/link';
import { $isRangeSelection, type BaseSelection } from 'lexical';

import { getSelectedNode } from './getSelectedNode';

export default function isSelectionLink(selection: BaseSelection | null): boolean {
  if (!$isRangeSelection(selection)) return false;

  const node = getSelectedNode(selection);
  const parent = node.getParent();
  return $isLinkNode(parent) || $isLinkNode(node);
}
