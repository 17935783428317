import cx from 'classnames';
import React from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import styles from './MessageClickable.scss';

interface Props {
  children: React.ReactNode;
  dataMessageId?: string | null;
  onClick?: (e: React.SyntheticEvent) => void;
  to?: LinkProps['to'];
  className?: string;
  isFromUser?: boolean;
  isCarousel?: boolean;
  active?: boolean;
}

function MessageClickable({
  to,
  onClick,
  children,
  className,
  isFromUser,
  isCarousel,
  active,
  dataMessageId,
}: Props): React.JSX.Element {
  const classNames = cx(styles.wrapper, className, {
    [styles.active]: active,
    [styles.wrapperFromUser]: isFromUser,
    [styles.isCarousel]: isCarousel,
  });

  if (to) {
    return (
      <Link data-messageid={dataMessageId} to={to} onClick={onClick} className={classNames}>
        {children}
      </Link>
    );
  }

  return (
    <div
      data-messageid={dataMessageId}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick?.(e);
        }
      }}
      tabIndex={0}
      onClick={onClick}
      className={classNames}
    >
      {children}
    </div>
  );
}

export default MessageClickable;
