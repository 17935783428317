import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';

import { cacheSkillSubscription, updateArrayInCache } from 'frontend/api/cacheHelpers';
import { BotBackupsDocument, MessageBackupsDocument } from 'frontend/api/generated';
import BotSubscriptions from 'frontend/api/queries/BotSubscriptions.query.graphql';
import { operations } from 'frontend/constants';
import { GetDialogue } from 'frontend/features/Build/graphql';
import { useBotOrSkill, useToast } from 'frontend/hooks';

const fetchPolicy = 'network-only';

const dialogueModUpdated =
  ({ buildIdObject, client, hasBuildPermission }) =>
  ({ dialogue: dialogueId, skill: skillId }) => {
    if (!hasBuildPermission) return;
    client.query({ query: GetDialogue, variables: { ...buildIdObject, skillId, dialogueId } });
  };

const subscribedToSkill =
  ({ botId, client }) =>
  (payload) => {
    const { id, skillId } = payload;
    cacheSkillSubscription(operations.ADD, id, { client, botId, skillId });
  };

const unsubscribedFromSkill =
  ({ botId, client }) =>
  (payload) => {
    const { id, skillId } = payload;
    cacheSkillSubscription(operations.REMOVE, id, { botId, client, skillId });
  };

const activateSkillSubscription =
  ({ client, buildIdObject, hasBuildPermission }) =>
  () => {
    if (!hasBuildPermission) return;
    client.query({ query: BotSubscriptions, variables: buildIdObject, fetchPolicy });
  };

const deactivateSkillSubscription =
  ({ buildIdObject, client, hasBuildPermission }) =>
  () => {
    if (!hasBuildPermission) return;
    client.query({ query: BotSubscriptions, variables: buildIdObject, fetchPolicy });
  };

const kindlyEntitiesInserted =
  ({ toast, botId }) =>
  () => {
    toast.linkWarning(
      `Kindly entities were automatically inserted in your samples. Disable the
      relevant Kindly entity if you don't want this (click this message)`,
      `/workspace/${botId}/settings/languages/entities`,
      { autoClose: 10 * 1000 },
    );
  };

const backupUpdate =
  ({ toast, client, buildIdObject }) =>
  ({ id, type, status, createdAt, backupFrom, backupTo }) => {
    if (type === 'message') {
      if (status === 'Error') toast.error(`Inbox backup failed (#${id})`);

      const newBackup = { id, status, createdAt, backupFrom, backupTo, __typename: 'MessageBackupType' };

      updateArrayInCache({
        variables: buildIdObject,
        pathToArrayInCache: 'messageBackups',
        query: MessageBackupsDocument,
        cache: client,
        arrayUpdate: (backups) => [newBackup, ...backups],
      });
    } else if (type === 'bot') {
      if (status === 'Error') {
        toast.error(`Bot backup failed (#${id})`);
      } else {
        toast.success(`Bot backup completed (#${id})`);
      }
      client.query({ query: BotBackupsDocument, variables: buildIdObject, fetchPolicy: 'network-only' });
    }
  };

export default (botLike) => {
  const client = useApolloClient();
  const toast = useToast();
  const { botId, buildIdObject, isBot } = useBotOrSkill({ ignoreNoBotOrSkill: true });
  const buildPermission = isBot ? 'view_templates' : 'read_skill_content';
  const hasBuildPermission = (botLike?.permissions ?? []).includes(buildPermission);

  const events = useMemo(
    () => ({
      'dialogue-mod-updated': dialogueModUpdated({ buildIdObject, client, hasBuildPermission }),
      'subscribed-to-skill': subscribedToSkill({ botId, client }),
      'unsubscribed-from-skill': unsubscribedFromSkill({ botId, client }),
      'activate-skill-subscription': activateSkillSubscription({ buildIdObject, client, hasBuildPermission }),
      'deactivate-skill-subscription': deactivateSkillSubscription({ buildIdObject, client, hasBuildPermission }),
      'kindly-entities-inserted': kindlyEntitiesInserted({ botId, toast }),
      backup: backupUpdate({ client, toast, buildIdObject }),
    }),
    [botId, buildIdObject, client, hasBuildPermission, toast],
  );

  return events;
};
