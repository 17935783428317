import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { EmptyState } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components';
import { useSageData } from 'frontend/features/Analytics/hooks';
import useFakeData from 'frontend/features/Analytics/hooks/useFakeData';
import { BUTTONS } from 'frontend/features/Analytics/queries';
import type { ButtonClicksGraphqlData } from 'frontend/features/Analytics/utils/analyticMockData';

import styles from './ButtonClicks.scss';
import OverviewItem from './OverviewItem';
import { getButtonIds, getTotalClicks } from './utils';

const N_BUTTONS = 5;

interface ButtonClicksProps {
  to: string;
  botId: string;
  filters: Record<string, unknown>;
  scope: string;
  priority: number;
}

function ButtonClicks({ to, botId, filters, scope, priority }: ButtonClicksProps) {
  const [buttonClickCount, setButtonClickCount] = useState(0);
  const {
    loading: sageLoading,
    error,
    data: sageData,
  } = useSageData(
    scope,
    '/buttons/most_clicked',
    { ...filters, limit: N_BUTTONS },
    { priority, filterOnButtonTypes: true },
  );

  const { fakeData: fakeButtonData, fakeDataEnabled } = useFakeData<ButtonClicksGraphqlData>('graphql:button_clicks');

  const { data: buttonData, loading: buttonsLoading } = useQuery<{
    buttons: { buttons: ButtonClicksGraphqlData };
  }>(BUTTONS, {
    variables: { botId, buttonIds: getButtonIds(sageData), includeDialogue: false },
    skip: sageLoading || Boolean(error),
  });

  const { data, loading } = useMemo(() => {
    if (fakeDataEnabled) {
      // useSageData will return fake data if fakeDataEnabled is true, so we use the same variable as when it's real data
      return { data: { sage: sageData, graphql: fakeButtonData }, loading: false };
    }

    if (sageLoading || buttonsLoading || !sageData || !buttonData) {
      return { data: { sage: [], graphql: [] }, loading: true };
    }

    return { data: { sage: sageData, graphql: buttonData?.buttons.buttons }, loading: false };
  }, [sageData, sageLoading, buttonData, buttonsLoading, fakeButtonData, fakeDataEnabled]);

  useEffect(() => {
    if (loading || error) {
      return;
    }
    setButtonClickCount(getTotalClicks(sageData));
  }, [loading, error, sageData, setButtonClickCount]);

  const isEmpty = data.sage.length === 0;
  const panelClassName = cx(styles.panel, { [styles.panelFullWidth]: loading || buttonsLoading || error || isEmpty });

  const title = 'Button clicks';
  const subtitle = (
    <>
      Total {buttonClickCount || 0} button clicks in selected period.
      <br />
      Showing top {N_BUTTONS} buttons:
    </>
  );

  return (
    <StatsPanel
      loading={loading || buttonsLoading}
      error={error}
      to={to}
      noPadding={!loading && !isEmpty && !error}
      title={title}
      subtitle={subtitle}
    >
      <div className={panelClassName}>
        {data.sage.map(({ count, ratio, button_id: buttonId }) => (
          <OverviewItem
            key={`button-clicks-item-${buttonId}`}
            buttons={data.graphql ?? []}
            buttonId={buttonId}
            count={count}
            ratio={ratio}
          />
        ))}
      </div>
      {isEmpty && <EmptyState />}
    </StatsPanel>
  );
}

export default ButtonClicks;
