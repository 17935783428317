import { Field, Form } from 'react-final-form';

import defaultBotAvatar from 'frontend/assets/images/bot_avatar.svg?url';
import { Button, FileUpload, FormErrors, Input, LoaderSwitch, PageBar, Panel } from 'frontend/components';
import { useGeneralForm } from 'frontend/features/BotSettings/hooks';
import { chain, max, required } from 'frontend/form/validators';

import styles from '../../styles.scss';

export default function BotMeta() {
  const { onSubmit, initialValues, loading, setFile } = useGeneralForm();

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={styles.generalLayout}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form: { change }, values }) => (
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>

              <Panel>
                <FormErrors />
                <h3 className={`${styles.sectionTitle} m-b-3`}>Workspace avatar</h3>
                <div className={styles.avatarContainer}>
                  <div
                    className={styles.portrait}
                    style={{ backgroundImage: `url(${values.bot?.avatarUrl || defaultBotAvatar})` }}
                  />
                  <div className={styles.upload}>
                    <Field component={Input} name="bot.avatarUrl" hidden />
                    <FileUpload
                      containerClassName={styles.uploadButton}
                      text="Upload"
                      accept="image/png,image/jpeg"
                      onUpload={(f) => {
                        change('bot.avatarUrl', f?.[0]?.source);
                        setFile!(f?.[0]?.file);
                      }}
                    />
                    {initialValues.bot?.avatarUrl && (
                      <Button flat onClick={() => change('bot.avatarUrl', null)}>
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
                <Panel.Separator />
                <h3 className={`${styles.sectionTitle} m-b-3`}>Bot name</h3>
                <div className={styles.inputContainer}>
                  <Field
                    component={Input}
                    label="Bot name"
                    name="bot.name"
                    className="m-b-md"
                    placeholder="Give me a name"
                    inputLimit={50}
                    validate={chain([required, max(50)])}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    component={Input}
                    name="bot.projectName"
                    label="Workspace name"
                    placeholder="Give me a workspace name"
                    inputLimit={50}
                    validate={max(50)}
                  />
                </div>
                <p className="m-t-sm text-color-light">Workspace name is only visible within the Kindly platform.</p>
              </Panel>
            </form>
          )}
        />
      </div>
    </LoaderSwitch>
  );
}
