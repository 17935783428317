import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { type DialogueType, DialoguesWithWebhooksDocument } from 'frontend/api/generated';
import BuildTypes from 'frontend/constants/buildTypes';
import { compileWebhookList } from 'frontend/features/BuildDashboard/utils';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

import { COLUMN_FIELDS, ORDERED_COLUMNS } from './constants';
import { DASHBOARD_VIEW } from '../../constants';
import { useWebhooksRenderMap } from '../../hooks';
import BuildResources from '../BuildResources';

const Webhooks = () => {
  const { buildIdObject, buildType } = useBotOrSkill();
  const [{ currentLanguage }] = useCurrentLanguage();
  const { data, loading } = useQuery(DialoguesWithWebhooksDocument, {
    variables: { ...buildIdObject, languageCode: currentLanguage, page: 1, pageSize: 1_000 },
    fetchPolicy: 'network-only',
  });
  const dialoguesWithWebhooks = data?.dialoguesWithWebhooks.dialogues;
  const noData = !loading && (dialoguesWithWebhooks?.length || 0) === 0;

  const webhooksList = useMemo(() => {
    const filteredDialogues = (dialoguesWithWebhooks || []).filter(
      (dialogue): dialogue is DialogueType => dialogue !== null,
    );
    return compileWebhookList(currentLanguage, filteredDialogues);
  }, [currentLanguage, dialoguesWithWebhooks]);

  const renderMap = useWebhooksRenderMap({ buildIdObject });

  return (
    <BuildResources
      resources={webhooksList}
      columnFields={COLUMN_FIELDS}
      orderedColumns={ORDERED_COLUMNS}
      renderMap={renderMap}
      dashboardView={DASHBOARD_VIEW.WEBHOOKS}
      loading={loading}
      buildType={buildType ?? BuildTypes.BOT}
      noData={noData}
    />
  );
};

export default Webhooks;
