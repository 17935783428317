import { last } from 'lodash';

import { AGENT_VIEW_FILTERS } from 'frontend/state/dux/inbox';

export const TICKET_STATUS = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  SOLVED: 'SOLVED',
};

export const SHOW_MAX_AGENTS = 5;

export const CACHE_TYPE = {
  ATTACHMENT_TYPE: 'AttachmentType',
  CHAT_MESSAGE_TYPE: 'ChatMessageType',
  CHAT_TYPE: 'ChatType',
  BUTTON_CLICK_TYPE: 'ButtonClickType',
  TICKET_TYPE: 'TicketType',
  TICKET_LOG_TYPE: 'TicketLogType',
  PROFILE_TYPE: 'ProfileType',
  USER_TYPE: 'UserType',
};

export const MESSAGE_TYPE = {
  URL: 'Url',
  TIMESTAMP: 'Timestamp',
  FEEDBACK: 'Feedback',
  USER_LEFT: 'User left',
};

export const CHAT_MESSAGES_LIMIT = Number(localStorage.getItem('CHAT_MESSAGES_LIMIT') || 100);

export const SENDER_TYPES = Object.freeze({
  AGENT: 'AGENT',
  BOT: 'BOT',
  EXTERNAL: 'EXTERNAL',
  SYSTEM: 'SYSTEM',
  USER: 'USER',
  WEBHOOK: 'WEBHOOK',
});
export type SenderType = (typeof SENDER_TYPES)[keyof typeof SENDER_TYPES];

export const ATTACHMENT_STATUS = Object.freeze({
  CLEAN: 'CLEAN',
  ERROR: 'ERROR',
  INFECTED: 'INFECTED',
  PROCESSING: 'PROCESSING',
});

export const EMPTY_CHAT_MESSAGE = Object.freeze({
  id: '',
  __typename: CACHE_TYPE.CHAT_MESSAGE_TYPE,
  attachmentIds: [],
  attachments: [],
  avatar: '',
  buttons: [],
  buttonsDisplayOrientation: 'VERTICAL',
  chatLanguageCode: '',
  chatSource: null,
  created: '',
  emailCc: [],
  exchangeId: null,
  exchangeType: null,
  form: null,
  formSubmission: {},
  fromBot: true,
  fromWebhook: false,
  handler: null,
  imageCarousel: null,
  imageCarouselSize: 0,
  isFollowup: false,
  messageReferences: {},
  message: '',
  messageWithKindlyEntities: '',
  name: '',
  replyCandidates: [],
  score: null,
  sender: null,
  skillSubscriptionId: null,
  suggestions: [],
  webHost: null,
  webPath: null,
});

const NON_SOLVED_TICKETS = [TICKET_STATUS.OPEN, TICKET_STATUS.PENDING];

export const VIEWS = new Map([
  ['unassigned_tickets', { ticketAgentFilter: AGENT_VIEW_FILTERS.UNASSIGNED, ticketStatusFilter: NON_SOLVED_TICKETS }],
  ['assigned_to_me', { ticketAgentFilter: AGENT_VIEW_FILTERS.MY_TICKETS, ticketStatusFilter: NON_SOLVED_TICKETS }],
  ['open_tickets', { ticketAgentFilter: null, ticketStatusFilter: [TICKET_STATUS.OPEN] }],
  ['pending_tickets', { ticketAgentFilter: null, ticketStatusFilter: [TICKET_STATUS.PENDING] }],
  ['solved_tickets', { ticketAgentFilter: null, ticketStatusFilter: [TICKET_STATUS.SOLVED] }],
  ['all_conversations', { ticketAgentFilter: null, ticketStatusFilter: null }],
]);

export const ALL_CONVERSATIONS_VIEW = last(Array.from(VIEWS.keys()));

export const HANDOVER_MODE = Object.freeze({
  DISABLED: { value: 'DISABLED', title: 'Handover is disabled' },
  EXTERNAL: { value: 'EXTERNAL', title: 'Handover is handled in an external platform' },
  PLATFORM: { value: 'PLATFORM', title: 'Handover is handled in the Inbox section in Kindly' },
});

/** @deprecated For new uses, use TS and the twin type. */
// TS-MIGRATE: Remove this variable when we've migrated the related files to TS.
export const HANDOVER_TYPES = Object.freeze({ LIVE_CHAT: 'LIVE_CHAT', EMAIL: 'EMAIL' });
export type HandoverType = 'LIVE_CHAT' | 'EMAIL';

export const FILTER_TYPES = Object.freeze({
  datePicker: 'dateFilter',
  STRING: ['ticketAgentFilter'],
});
