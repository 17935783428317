import cx from 'classnames';
import { useRef, useState } from 'react';

import styles from './LabelBadgeSlider.scss';

const DOT_WIDTH = 15;

interface LabelCalcs {
  isLabelOverflowing: boolean;
  labelWidth: string;
}

interface LabelStyles extends React.CSSProperties {
  '--_label-color'?: string;
  '--_label-background-color'?: string;
  '--_label-overflow-delta'?: string;
}

interface Props {
  /** If true, style the label as clickable (e.g. cursor: pointer). */
  isClickable?: boolean;
  /** The label name. */
  labelName: string;
  /** The color of the dot. */
  labelColor?: string;
  /** The background color of the label. */
  labelBackground?: string;
  /** The html `for` attribute to bind the label to an input. */
  htmlFor?: string;
  className?: string;
  /** The shape of the label. */
  shape?: 'circle' | 'square';
}

/**
 * Colored label badge that automatically scrolls long text on hover.
 */
export default function LabelBadgeSlider({
  labelName,
  labelColor,
  labelBackground,
  htmlFor,
  className,
  isClickable,
  shape = 'circle',
}: Props) {
  const [labelCalcs, setLabelCalcs] = useState<LabelCalcs>({ isLabelOverflowing: false, labelWidth: '100%' });
  const span = useRef<HTMLSpanElement>(null);
  const label = useRef<HTMLLabelElement>(null);

  const handleLabelHover = () => {
    if (!span.current || !label.current) return;
    const isLabelOverflowing = span.current.scrollWidth + DOT_WIDTH > label.current.offsetWidth;
    const labelWidth = isLabelOverflowing ? `${label.current.offsetWidth * 0.2}px` : '100%';
    setLabelCalcs({ isLabelOverflowing, labelWidth });
  };

  return (
    <label
      className={cx(styles.label, { [styles.clickable]: isClickable }, className)}
      onMouseEnter={handleLabelHover}
      htmlFor={htmlFor}
      style={
        {
          '--_label-color': labelColor,
          '--_label-background-color': labelBackground,
          '--_label-overflow-delta': labelCalcs.labelWidth,
        } as LabelStyles
      }
      ref={label}
    >
      <span className={cx({ [styles.square]: shape === 'square', [styles.dot]: shape === 'circle' })} />

      <span ref={span} className={styles.text}>
        {labelName}
      </span>
    </label>
  );
}
