import { useParams } from 'react-router-dom';

import { AnalyticsContent, Grid } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { useBooleanState } from 'frontend/hooks';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import Filters, { FilterInfo } from '../../../Filters';
import ChatbubblePages from '../ChatbubblePages/ChatbubblePages';

const HIDE_FILTERS = { granularity: true, sources: true, engagementCampaigns: true, nudges: true };
const SKIP = Object.keys(HIDE_FILTERS);

const ChatbubblePagesDetail = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const { botId } = useParams();
  const filters = useFilters({ skip: SKIP });

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <Grid columns={1}>
          <ChatbubblePages scope={sageScope(SAGE_RESOURCE.BOT, botId)} filters={{ ...filters, limit: 100 }} />
        </Grid>
      </AnalyticsContent>
    </>
  );
};

export default ChatbubblePagesDetail;
