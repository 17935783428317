import { flushSync } from 'react-dom';

const userPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
const isViewTransitionSupported = 'startViewTransition' in document;

interface ViewTransition {
  /** A Promise that fulfills once the transition animation is finished, and the new page view is visible and interactive to the user. */
  finished: Promise<void>;
  /** A Promise that fulfills once the pseudo-element tree is created and the transition animation is about to start. */
  ready: Promise<void>;
  /** Skips the animation part of the view transition, but doesn't skip running the document.startViewTransition() callback that updates the DOM. */
  skipTransition(): void;
  types?: Set<string>;
  /** A Promise that fulfills when the promise returned by the document.startViewTransition() method's callback fulfills. */
  updateCallbackDone: Promise<void>;
}

type DocumentWithViewTransition = Document & {
  startViewTransition(updateCallback: () => void): Promise<ViewTransition>;
};

/**
 * Starts a view transition using the View Transitions API if available.
 * This function ensures that the DOM updates are synchronized using `flushSync`.
 *
 * @param cbUpdatingDOM - A callback function that performs DOM updates.
 */
export default async function startViewTransition(
  cbUpdatingDOM: Parameters<DocumentWithViewTransition['startViewTransition']>[0],
): Promise<Awaited<ReturnType<DocumentWithViewTransition['startViewTransition']>> | undefined> {
  if (userPrefersReducedMotion || !isViewTransitionSupported) {
    cbUpdatingDOM();
    return undefined;
  }

  const doc = document as DocumentWithViewTransition;
  const transition = await doc.startViewTransition(() => {
    flushSync(cbUpdatingDOM);
  });
  return transition;
}
