// TODO: Rename Checkbox to CheckboxAndLabel (using this component), then rename this component to Checkbox.
// Also, decide for a style on :hover:checked when discussion about lighten/darken is resolved.

import cx from 'classnames';
import type { FieldValues, Path, RegisterOptions, UseFormReturn } from 'react-hook-form';

import styles from './CheckboxOnly.scss';

interface CheckboxOnlyProps<T extends FieldValues> extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  /** The name of the field. */
  name: Path<T>;
  /** The RHF methods returned by `useForm()`. */
  rhfMethods?: UseFormReturn<T>;
  /** The RHF register options. */
  rhfOptions?: RegisterOptions<T>;
}

/** Kindly checkbox with all the default styles and a nice animation.
 *
 * It's just an `<input type="checkbox">` element, using modern CSS without hacks to style/replace the checkbox.
 * It's also usable with `react-hook-form` when `rhfMethods` and `name` are provided.
 */
export default function CheckboxOnly<T extends FieldValues>({
  className,
  name,
  rhfMethods,
  rhfOptions,
  ...rest
}: CheckboxOnlyProps<T>) {
  return (
    <input
      type="checkbox"
      className={cx(styles.checkbox, className)}
      name={name}
      {...rhfMethods?.register(name, rhfOptions)}
      {...rest}
    />
  );
}
