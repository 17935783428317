import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AnalyticsContent, Grid, Heading } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import Filters, { FilterInfo } from 'frontend/features/Analytics/views/Filters';
import { Interactions, Sessions } from 'frontend/features/Analytics/views/Sessions/components';
import { useBooleanState } from 'frontend/hooks';
import { connectPlatformAnalytics, disconnectPlatformAnalytics } from 'frontend/state/dux/analytics/dashboard';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';

import KindlyChatPresence from './KindlyChatPresence';

const HIDE_FILTERS = { language: true, sources: true, engagementCampaigns: true, nudges: true };
const SKIP = Object.keys(HIDE_FILTERS);

const PlatformAnalytics = () => {
  const filters = useFilters({ skip: SKIP });
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectPlatformAnalytics());
  }, [dispatch]);
  useEffect(() => () => dispatch(disconnectPlatformAnalytics()), [dispatch]);

  const scope = sageScope(SAGE_RESOURCE.PLATFORM);
  return (
    <>
      <Filters
        hideFilterView={hideFilterView}
        filterViewShowing={filterViewShowing}
        hideFilter={HIDE_FILTERS}
        platformAnalytics
      />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} includeLanguages={false} />
        <div>
          <Grid>
            <div>
              <Heading title="Users online" />
              <KindlyChatPresence />
            </div>
            <div />
          </Grid>
          <Grid>
            <div>
              <Sessions scope={scope} filters={filters} isPreview />
            </div>
            <div>
              <Interactions scope={scope} filters={filters} isPreview />
            </div>
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default PlatformAnalytics;
