import cx from 'classnames';
import { uniqueId } from 'lodash';
import { useRef, useState } from 'react';

import { CheckboxOnly, Loader } from 'frontend/components';
import DotsButton from 'frontend/features/Labels/Label/DotsButton';
import LabelBadgeSlider from 'frontend/features/Labels/Label/LabelBadgeSlider';
import { LABEL_PHASES } from 'frontend/features/Labels/constants';
import type { Label as LabelType } from 'frontend/propTypes/LabelType';

import styles from './Label.scss';

interface CommonProps {
  label: LabelType;
  handleToggle: (label: LabelType, isActive: boolean) => void;
  isActive: boolean;
  shape?: 'circle' | 'square';
}

export interface LabelEditProps {
  canEditLabels: true;
  setInput: (input: string) => void;
  setLabelManagerPhase: (phase: string) => void;
  setLabelToManage: React.Dispatch<React.SetStateAction<LabelType>>;
  inputRef: React.RefObject<HTMLInputElement>;
}

export interface LabelNonEditProps {
  canEditLabels?: false;
  setInput?: never;
  setLabelManagerPhase?: never;
  setLabelToManage?: never;
  inputRef?: never;
}

type Props = CommonProps & (LabelEditProps | LabelNonEditProps);

function Label({
  label,
  handleToggle,
  canEditLabels,
  isActive,
  setLabelManagerPhase,
  setLabelToManage,
  setInput,
  inputRef,
  shape,
}: Props) {
  const { current: labelId } = useRef(uniqueId(label.name)); // To avoid same ids when component is used multiple times on a page
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = (e) => {
    e.stopPropagation();
    setLabelToManage?.(label);
    setLabelManagerPhase?.(LABEL_PHASES.DELETE);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setLabelToManage?.(label);
    setLabelManagerPhase?.(LABEL_PHASES.EDIT);
    setInput?.(label.name);
    inputRef?.current?.focus();
  };

  const handleLabelChange = async (changeLabel: LabelType, status: boolean) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await handleToggle(label, status);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <li data-label-hover className={styles.wrapper}>
      <div className={styles.checkboxContainer}>
        {isLoading && <Loader size="small" className={styles.checkboxLoader} />}
        <CheckboxOnly
          className={cx(styles.checkbox, { [styles.checkboxLoading]: isLoading })}
          id={labelId}
          checked={isActive}
          onChange={() => handleLabelChange(label, !isActive)}
          onKeyDown={(e) => e.key === 'Enter' && handleToggle(label, !isActive)}
          name={`label-${labelId}`}
        />
      </div>

      <LabelBadgeSlider
        htmlFor={labelId}
        labelName={label.name}
        labelColor={label.color}
        labelBackground={label.background}
        isClickable
        shape={shape}
      />

      {canEditLabels && <DotsButton handleDelete={handleDelete} handleEdit={handleEdit} />}
    </li>
  );
}

export default Label;
