import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import cx from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  type LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  type TextFormatType,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import { ExternalLink, Trash } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import { validateUrl } from 'frontend/components/LexicalEditor/utils/validateUrl';
import startViewTransition from 'frontend/utils/startViewTransition';

import styles from './ToolbarContent.scss';
import LinkIcon from '../../../icons/LinkIcon';
import ListOl from '../../../icons/ListOl';
import ListUl from '../../../icons/ListUl';
import Strikethrough from '../../../icons/Strikethrough';
import TypeBold from '../../../icons/TypeBold';
import TypeItalic from '../../../icons/TypeItalic';
import { getSelectedNode } from '../../../utils/getSelectedNode';

type BlockType = 'ul' | 'ol' | 'paragraph';

const LOW_PRIORITY = 1;
const BUTTON_TO_ICON = {
  bold: <TypeBold className={styles.icon} />,
  italic: <TypeItalic className={styles.icon} />,
  ul: <ListUl className={styles.icon} />,
  ol: <ListOl className={styles.icon} />,
  link: <LinkIcon className={styles.icon} />,
  strikethrough: <Strikethrough className={styles.icon} />,
};

/**
 * Extract the toolbar content into a separate component that can be reused
 */
export default function ToolbarContent({
  editor,
  setToolbarVisible,
}: {
  editor: LexicalEditor;
  setToolbarVisible: (visible: boolean) => void;
}) {
  const [floatingEditorMode, setFloatingEditorMode] = useState<'TOOLBAR' | 'LINK-ADD' | 'LINK-EDIT'>('TOOLBAR');
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isStrikeThrough, setIsStrikeThrough] = useState(false);

  const handleOnClick = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const formatList = (listType) => {
    if (listType === 'ol' && blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    } else if (listType === 'ul' && blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  const handleButtonLinkClick = () => {
    startViewTransition(() => {
      setFloatingEditorMode('LINK-ADD');
    });
  };

  const handleDeleteLink = () => {
    setToolbarVisible(false);
    setFloatingEditorMode('TOOLBAR');
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
  };

  /* Check if the selected text has any format */
  useEffect(
    () =>
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
              // Update block type
              if ($isListNode(element)) {
                const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                const type = parentList ? parentList.getTag() : element.getTag();
                setBlockType(type);
              } else {
                const type = element.getType();
                setBlockType(type as 'paragraph');
              }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsStrikeThrough(selection.hasFormat('strikethrough'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
              setIsLink(true);
            } else {
              setIsLink(false);
            }
          }
        });
      }),
    [editor],
  );

  /* Disable underline formatting */
  useEffect(() => {
    editor.registerCommand(FORMAT_TEXT_COMMAND, (event) => event === 'underline', 1);
  }, [editor]);

  const [linkUrl, setLinkUrl] = useState('');
  const [hasLinkError, setHasLinkError] = useState(false);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setFloatingEditorMode('LINK-EDIT');
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setFloatingEditorMode('LINK-EDIT');
        setLinkUrl(node.getURL());
      } else {
        setFloatingEditorMode('TOOLBAR');
        setLinkUrl('');
      }
    }

    return true;
  }, []);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          LOW_PRIORITY,
        ),
      ),
    [editor, updateLinkEditor],
  );

  return (
    <>
      {floatingEditorMode === 'TOOLBAR' && (
        <>
          {['bold', 'italic', 'strikethrough'].map((value) => (
            <button
              type="button"
              key={value}
              onClick={() => handleOnClick(value.toLowerCase() as TextFormatType)}
              className={cx(styles.button, {
                [styles.buttonActive]:
                  (value === 'bold' && isBold) ||
                  (value === 'italic' && isItalic) ||
                  (value === 'strikethrough' && isStrikeThrough),
              })}
            >
              {BUTTON_TO_ICON[value]}
            </button>
          ))}
          {['ul', 'ol'].map((value) => (
            <button
              type="button"
              key={value}
              onClick={() => formatList(value)}
              className={cx(styles.button, { [styles.buttonActive]: blockType === value })}
            >
              {BUTTON_TO_ICON[value]}
            </button>
          ))}
          <button
            type="button"
            onClick={handleButtonLinkClick}
            className={cx(styles.button, { [styles.buttonActive]: isLink })}
          >
            {BUTTON_TO_ICON.link}
          </button>
        </>
      )}

      {(floatingEditorMode === 'LINK-ADD' || floatingEditorMode === 'LINK-EDIT') && (
        <div className={styles.linkAddMode}>
          <div className={styles.linkAddInputWrapper}>
            <input
              type="text"
              placeholder="Type in URL..."
              className={cx(styles.linkInput, { [styles.linkInputError]: hasLinkError })}
              value={linkUrl}
              onChange={(e) => {
                setLinkUrl(e.currentTarget.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (validateUrl(linkUrl)) {
                    setHasLinkError(false);
                  } else {
                    setHasLinkError(true);
                    setTimeout(() => {
                      setHasLinkError(false);
                    }, 1000);
                    return;
                  }

                  setToolbarVisible(false);

                  if (linkUrl.trim() !== '') {
                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                    setFloatingEditorMode('TOOLBAR');
                    setLinkUrl('');
                  }
                } else if (e.key === 'Escape') {
                  setToolbarVisible(false);
                  setFloatingEditorMode('TOOLBAR');
                  setLinkUrl('');
                }
              }}
            />
            {linkUrl.length <= 21 && floatingEditorMode === 'LINK-ADD' && (
              <div className={styles.toAddTextContainer}>
                <span className={styles.toAddArrow}>↩</span>
                <span className={styles.toAddText}>to add</span>
              </div>
            )}
          </div>
          {floatingEditorMode === 'LINK-EDIT' && (
            <a href={linkUrl} target="_blank" rel="noreferrer" className={styles.linkButton}>
              <Icon component={ExternalLink} />
            </a>
          )}
          <button type="button" onClick={handleDeleteLink} className={styles.linkButton}>
            <Icon component={Trash} />
          </button>
        </div>
      )}
    </>
  );
}
