import { useQuery } from '@apollo/client';
import { find } from 'lodash';
import { useMemo } from 'react';

import { DialoguesByIdDocument } from 'frontend/api/generated';
import { DialogueList } from 'frontend/components/Stats';
import type { DialogueType } from 'frontend/constants/dialogueTypes';
import StatsPanel from 'frontend/features/Analytics/components/StatsPanel/StatsPanel';
import { useSageData } from 'frontend/features/Analytics/hooks';
import type { FrequentDialoguesGraphqlData } from 'frontend/features/Analytics/utils/analyticMockData';
import type { FrequentDialoguesData } from 'frontend/features/Analytics/utils/sageData';
import type { LabelColor } from 'frontend/features/Library/propTypes/ColorLabelType';
import { useCurrentLanguage } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import type { QueryParam } from 'frontend/utils/stringifyQueryParamsWithBrackets';

import useFakeData from '../../hooks/useFakeData';

function mergeSageWithKindly(
  sageData: FrequentDialoguesData,
  graphqlData: FrequentDialoguesGraphqlData,
): {
  total: number;
  coverage: number;
  dialogues: {
    id: string;
    title: Record<string, string>;
    dialogueType?: DialogueType;
    colorLabel?: LabelColor;
    url: string | undefined;
    found: boolean;
    count: number;
    ratio: number;
  }[];
} {
  return {
    total: sageData.reduce((accumulator, { count }) => accumulator + count, 0),
    coverage: sageData.reduce((accumulator, { ratio }) => accumulator + ratio, 0.0),
    dialogues: sageData.map(({ dialogue_id: id, ...sageDialogue }) => {
      const kindlyDialogue = find(graphqlData, { id });
      if (!kindlyDialogue) {
        return { ...sageDialogue, id, title: { default: `Deleted dialogue (${id})` }, url: undefined, found: false };
      }
      return { ...sageDialogue, ...kindlyDialogue, found: true };
    }),
  };
}

function asPercentage(f: number): string {
  return `${(f * 100).toFixed(1)}%`;
}

interface FrequentDialoguesProps {
  botId: string;
  filters: Record<string, QueryParam>;
  to?: string;
  scope: string;
  priority?: number;
  isPreview?: boolean;
  faqLimit?: number;
}

function FrequentDialogues({ filters, to, botId, scope, priority, isPreview, faqLimit }: FrequentDialoguesProps) {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canViewDialogues = hasBotPerms('view_templates');

  const {
    data: sageData,
    loading: sageLoading,
    error: sageError,
  } = useSageData(scope, '/dialogues/frequently_triggered', filters, {
    priority,
  });

  const dialogueIds = useMemo(() => {
    if (sageLoading || !sageData) {
      return [];
    }
    return sageData.map(({ dialogue_id: id }) => id);
  }, [sageData, sageLoading]);

  const { fakeData: fakeGraphqlData, fakeDataEnabled } =
    useFakeData<FrequentDialoguesGraphqlData>('graphql:frequent_dialogues');

  const {
    data: graphqlData,
    loading: graphqlLoading,
    error: graphqlError,
  } = useQuery<{
    dialoguesById: FrequentDialoguesGraphqlData;
  }>(DialoguesByIdDocument, {
    variables: { botId, dialogueIds },
  });

  const { loading, data, error } = useMemo(() => {
    if (fakeDataEnabled) {
      return {
        loading: false,
        data: mergeSageWithKindly(sageData, fakeGraphqlData as FrequentDialoguesGraphqlData),
        error: undefined,
      };
    }

    if (sageLoading || graphqlLoading || !sageData || !graphqlData || sageError || graphqlError) {
      return { loading: true, error: sageError || graphqlError?.message, data: null };
    }

    return { loading: false, data: mergeSageWithKindly(sageData, graphqlData.dialoguesById), error: undefined };
  }, [sageData, sageLoading, sageError, graphqlData, graphqlLoading, graphqlError, fakeGraphqlData, fakeDataEnabled]);

  const title = 'Frequent dialogues';
  const subtitle = `The top ${faqLimit} most used dialogues`;

  return (
    <StatsPanel loading={loading} error={error} to={to} title={title} subtitle={subtitle}>
      {data && (
        <DialogueList
          dialogues={data.dialogues}
          currentLanguage={currentLanguage}
          isPreview={isPreview}
          canViewDialogues={canViewDialogues}
        >
          {`${asPercentage(data.coverage)} of all used dialogues`}
        </DialogueList>
      )}
    </StatsPanel>
  );
}

export default FrequentDialogues;
