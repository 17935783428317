/* eslint-disable camelcase */
import { Fragment } from 'react';

import type {
  AttachmentType,
  BuildFormTextType,
  BuildFormType,
  FieldValidatorType,
  FormFieldType,
  FormSubmissionType,
  FormsFormFieldAffixChoices,
  FormsFormFieldInputTypeChoices,
} from 'frontend/api/generated';
import { ChevronDown, ChevronRight, UnorderedList } from 'frontend/assets/icons';
import { Button, Icon, Input } from 'frontend/components';
import inputTypes from 'frontend/features/Build/components/Forms/utils/constants';
import { findFieldValidator } from 'frontend/features/Build/components/Forms/utils/helpers';
import { renderFileText } from 'frontend/features/Build/components/Preview/Preview';

import styles from './LogForm.scss';
import FileComponent from '../FileComponent/FileComponent';

enum FORM_SUBMISSION_STATES {
  ACTIVE = 'ACTIVE',
  UNANSWERED = 'UNANSWERED',
  SUBMITTED = 'SUBMITTED',
  CANCELED = 'CANCELED',
  ERRORED = 'ERRORED',
}

type SerializedFormFieldTextType = {
  label: string;
  help_text?: string;
  affix_value?: string;
  placeholder_text?: string;
};

type SerializedFormField = {
  affix?: FormsFormFieldAffixChoices;
  attributes?: {
    default_value?: string;
    step?: number;
    options?: { label: string; value: string; id: string }[];
  };
  created_at: string;
  form: BuildFormType;
  id: string;
  input_type: FormsFormFieldInputTypeChoices;
  order: number;
  required: boolean;
  slug: string;
  texts?: SerializedFormFieldTextType;
  updated_at: string;
  validators: FieldValidatorType[];
};

interface ActiveLogFormProps {
  fields: SerializedFormField[];
  submitButtonText: string;
  cancelButtonText: string;
}

const renderInput = (field: SerializedFormField) => {
  const { label, placeholder_text, affix_value } = field.texts as SerializedFormFieldTextType;

  switch (field.input_type) {
    case inputTypes.CHECKBOX:
    case inputTypes.RADIO:
      return (
        <div className={styles.formInsightsCheckbox}>
          <div className={styles.formInsightsInputLabelWrapper}>
            {label} {field?.required && <div className={styles.formInsightsInputRequired}>&#42;</div>}
          </div>
          {field.attributes?.options?.map((option) => (
            <label className={styles.insightsCheckboxWrapper} key={option.value} htmlFor={option.value}>
              <Input
                id={option.id}
                input={{
                  name: option.id,
                }}
                className={styles.formInsightsInput}
                disabled
                readOnly
                inputType={field.input_type}
              />
              <div className={styles.insightsCheckboxLabel}>{option.label}</div>
            </label>
          ))}
        </div>
      );
    case inputTypes.FILE:
      return (
        <>
          <Input
            className={styles.fileInputPreviewInput}
            disabled
            input={{
              name: `field_${field.input_type}_${field.id}`,
            }}
            label={<div className={styles.formInsightsInputLabelWrapper}>{label}</div>}
            inputType={field.input_type}
          />
          <div className={styles.fileInputPreview}>{renderFileText(placeholder_text ?? '', field.slug)}</div>
        </>
      );
    default:
      return (
        <Input
          input={{
            name: `field_preview_${field.input_type}_${field.slug}`,
          }}
          className={styles.formInsightsInput}
          disabled
          multiline={field.input_type === inputTypes.TEXTAREA}
          minRows={field.input_type === inputTypes.TEXTAREA ? 2 : undefined}
          adornmentPosition={field.input_type !== inputTypes.RANGE && field?.affix === 'PREFIX' ? 'left' : 'right'}
          adornment={
            <>
              {field.input_type !== inputTypes.RANGE && field.affix && !!affix_value && <div>{affix_value}</div>}
              {field.input_type === inputTypes.SELECT && <Icon component={ChevronDown} />}
            </>
          }
          inputType={field.input_type}
          label={<div className={styles.formInsightsInputLabelWrapper}>{label}</div>}
          placeholder={placeholder_text || field.input_type === inputTypes.SELECT ? '' : 'Type here'}
        />
      );
  }
};

const ActiveChatForm = ({ fields, submitButtonText, cancelButtonText }: ActiveLogFormProps) => (
  <>
    <div className={styles.formInsightsContent}>
      {fields.map((field) => {
        const { help_text } = field.texts as SerializedFormFieldTextType;

        const maxLength = findFieldValidator(field.validators, 'max_length')?.max_length;
        return (
          <Fragment key={`form_preview_field_${field.order}`}>
            <div className={styles.formInsightsInputContainer}>
              {renderInput(field)}
              {!!maxLength && <div className={styles.formInsightsMaxLength}>{`0 / ${maxLength}`}</div>}
            </div>
            {!!help_text && <div className={styles.formInsightsHelperText}>{help_text}</div>}
          </Fragment>
        );
      })}
    </div>
    <div className={styles.formInsightsActions}>
      <Button className={styles.formInsightsBtnCancel} disabled color="white" text={cancelButtonText} />
      <Button
        className={styles.formInsightsBtnSubmit}
        disabled
        color="gray"
        iconPosition="right"
        text={submitButtonText}
        icon={ChevronRight}
      />
    </div>
  </>
);

const getFormSubmissionText = (state, formTexts) => {
  if (!state || !formTexts) return null;

  switch (state) {
    case FORM_SUBMISSION_STATES.ERRORED:
      return formTexts.error_text;
    case FORM_SUBMISSION_STATES.CANCELED:
      return formTexts.cancel_text;
    default:
      return formTexts.unanswered_text;
  }
};

const getSubmittedValue = (value, formField, attachments) => {
  if (formField?.input_type) {
    if (inputTypes.FILE === formField?.input_type) {
      // value is supposed to be a comma separated list of attachment ids. An earlier bug caused it to be an object.
      // This condition is to avoid a crash when the value is an object.
      if (typeof value !== 'string') {
        return null;
      }
      const values = value.split(',');
      return values.map((attachmentId) => {
        const attachment = attachments.find((attachement) => attachmentId === attachement.id);
        if (!attachment) return null;
        return <FileComponent file={attachment} key={`submitted_form_value_${attachmentId}`} />;
      });
    }
    if ([inputTypes.SELECT, inputTypes.CHECKBOX, inputTypes.RADIO].includes(formField?.input_type)) {
      if (Array.isArray(value)) {
        const foundValue = formField?.attributes?.options.filter((option) => value.includes(option.value));
        if (foundValue.length) {
          return foundValue.map((val) => <div key={`submitted_form_value_${val.id}`}>{val.label}</div>);
        }
      }
      const foundValue = formField?.attributes?.options.find((option) => value === option.value);
      if (foundValue) {
        return foundValue?.label || value;
      }
    }
  }
  return value;
};

const SubmittedForm = ({
  formSubmission,
  formTexts,
  formFields,
  attachments,
}: {
  formSubmission?: Partial<FormSubmissionType> | null;
  formTexts: BuildFormTextType[];
  formFields: FormFieldType[];
  attachments: AttachmentType[];
}) => (
  <div className={styles.formSubmissionWrapper}>
    {formSubmission?.state === FORM_SUBMISSION_STATES.SUBMITTED ? (
      <div className={styles.formSubmittedContext}>
        {formSubmission.context && (
          <div className={styles.formSubmittedContextWrapper}>
            {Object.entries(formSubmission.context).map(([key, value]) => {
              const formField = formFields.find((field) => field.slug === key) as FormFieldType;
              if (!formField) return null;
              const { texts } = formField;
              return (
                <Fragment key={`form_insights_context_${key}`}>
                  <div className={styles.formSubmittedContextTitle}>{texts?.label}</div>
                  <div className={styles.formSubmittedContextValue}>
                    {formField.affix === 'PREFIX' && (texts as unknown as { affix_value: string })?.affix_value}
                    {getSubmittedValue(value, formField, attachments)}{' '}
                    {formField.affix === 'SUFFIX' && (texts as unknown as { affix_value: string })?.affix_value}
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    ) : (
      <div className={styles.submittedFormText}>{getFormSubmissionText(formSubmission?.state, formTexts)}</div>
    )}
  </div>
);

const LogForm = ({
  form,
  formSubmission,
  attachments,
}: {
  form: string | BuildFormType;
  formSubmission?: Partial<FormSubmissionType> | null;
  attachments: AttachmentType[];
}) => {
  // The form comes as string from the backend, we parse it
  // in any case if it comes as object, we just take it as it is
  const parsedForm = typeof form === 'string' ? JSON.parse(form) : form;
  const { texts: formTexts, fields } = parsedForm;

  if (!parsedForm || !Object.keys(parsedForm).length || !parsedForm.fields?.length) return null;

  return (
    <div className={styles.formInsightsWrapper}>
      <div className={styles.formInsightsContainer}>
        {formTexts?.title && (
          <div className={styles.formInsightsTitle}>
            <Icon component={UnorderedList} />
            {formTexts?.title}
          </div>
        )}
        {formSubmission?.state === FORM_SUBMISSION_STATES.ACTIVE && (
          <ActiveChatForm
            fields={fields}
            submitButtonText={formTexts.submit_button_text}
            cancelButtonText={formTexts.cancel_button_text}
          />
        )}
        {formSubmission?.state !== FORM_SUBMISSION_STATES.ACTIVE && (
          <SubmittedForm
            formSubmission={formSubmission}
            formTexts={formTexts}
            formFields={fields}
            attachments={attachments}
          />
        )}
      </div>
    </div>
  );
};

export default LogForm;
