export default function ListOl({ className, color = 'currentColor' }: { className?: string; color?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 9.25V5.75L5.75 6.25M7.25 9.25H5.75M7.25 9.25H8.25M11.75 7.75H18.25M11.75 16.25H18.25M8.25 18.25H5.75L7.9768 16.2116C8.15087 16.0523 8.25 15.8271 8.25 15.5912C8.25 15.1266 7.8734 14.75 7.40884 14.75H6.25C5.97386 14.75 5.75 14.9739 5.75 15.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
