import { ApolloProvider } from '@apollo/client';
import { ModalProvider } from 'react-modal-hook';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// FIXME: Move these to their respective components instead of loading right away
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import DndContainer from 'frontend/features/Dnd';
import { ToastContainer } from 'frontend/features/Toast';
import { useGoogleAnalytics, useTabFocus } from 'frontend/hooks';
import Routes from 'frontend/routes';
import store from 'frontend/state';

import client from './config/client';
import MixpanelProvider from './config/mixpanel';

const Contents = () => {
  useGoogleAnalytics();

  return (
    <>
      <ToastContainer />
      <ModalProvider>
        <Routes />
      </ModalProvider>
    </>
  );
};

const App = () => {
  useTabFocus();

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <MixpanelProvider>
            <DndContainer>
              <Contents />
            </DndContainer>
          </MixpanelProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
