import type { ButtonTypeEnum } from 'frontend/api/generated';
import { BUTTON_TYPES, LINK_STATE, NON_STANDARD_BUTTON_TYPES } from 'frontend/features/Build/constants';

export const EXTRA_FILTER_TYPES = Object.freeze({ ALL: 'all', [LINK_STATE.BROKEN]: 'BROKEN' });

export const buttonTypes = Object.values({ ...EXTRA_FILTER_TYPES, ...BUTTON_TYPES }).filter(
  (type) => !(NON_STANDARD_BUTTON_TYPES as string[]).includes(type),
);

export const COLUMN_FIELDS = ['buttonType', 'label', 'value', 'dialogue', 'actions'] as const;

export const MAP_BUTTON_FIELDS_TO_COLUMN_FIELDS = {
  buttonType: 'BUTTON_TYPE',
  label: 'LABEL',
  value: 'VALUE',
} as const;

export const MAP_BUTTON_TYPE: Record<string, ButtonTypeEnum> = {
  quick_reply: 'QUICK_REPLY',
  link: 'EXTERNAL_LINK',
  email: 'EMAIL',
  abort_followup: 'ABORT_FOLLOWUP',
  checkbox: 'CHECKBOX',
  submit: 'SUBMIT',
  phone: 'PHONE',
  upload_attachment: 'UPLOAD_ATTACHMENT',
  contact_details: 'CONTACT_DETAILS',
  takeover_request: 'HANDOVER_REQUEST',
  privacy_delete: 'PRIVACY_DELETE',
  privacy_export: 'PRIVACY_EXPORT',
  dialogue_trigger: 'DIALOGUE_TRIGGER',
  slider: 'SLIDER',
};
