import { Folder } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import styles from 'frontend/features/Build/components/DialoguePath/DialoguePath.scss';
import type { LibraryItemType } from 'frontend/features/Build/components/DialoguePath/constants';
import { formatType } from 'frontend/features/Build/components/DialoguePath/utils';

export function FolderElement({ name, type }: { name?: string; type: LibraryItemType }) {
  return (
    <span className={styles.folderElement}>
      <Icon component={Folder} />
      {name || formatType(type)}
    </span>
  );
}
