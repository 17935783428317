import { CheckboxOnly } from 'frontend/components';
import { DIALOGUE_LABEL_COLORS } from 'frontend/features/Library/constants';

import styles from './menus-styles.scss';
import type { MenuProps } from './types';

export const ColorMenu = ({ onFilterChange, checked, setChecked }: MenuProps) => {
  const handleCheck = (key) => {
    const isChecked = !checked.includes(key);

    if (isChecked) {
      checked = [...checked, key];
    } else {
      checked = checked.filter((val) => val !== key);
    }

    if (setChecked) {
      setChecked(checked);
    }
    onFilterChange(checked); // FIXME handleChange naming
  };

  return (
    <ul className={styles.list}>
      {Array.from(DIALOGUE_LABEL_COLORS).map(([colorName, colorCode]) => (
        <li className={styles.item} key={colorName}>
          <label
            htmlFor={`library-search-filters-color-${colorName}`}
            style={{
              // @ts-expect-error fix TS inline style
              '--_circle-background-color': colorCode,
            }}
          >
            <span className={styles.circle} />
            <CheckboxOnly
              className={styles.checkbox}
              checked={checked.includes(colorName)}
              id={`library-search-filters-color-${colorName}`}
              onChange={() => handleCheck(colorName)}
              name={`library-search-filters-color-${colorName}`}
            />
          </label>
        </li>
      ))}
    </ul>
  );
};
