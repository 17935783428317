import { isNil } from 'lodash';

export type BreadCrumb = { id: string; type: 'dialogues' | 'skill' | 'system' | 'topic' | 'greeting' | 'fallback' };
export type BreadCrumbCheck = (path: string, breadcrumbs: BreadCrumb[] | null) => boolean;

export function isSubscriptionPath(path: string, breadcrumbs: BreadCrumb[] | null): boolean {
  return path.includes('/workspace/') && Boolean(breadcrumbs?.find(({ type }) => type === 'skill'));
}

export function isRegularDialoguePath(path: string, breadcrumbs: BreadCrumb[] | null): boolean {
  // Wait for the breadcrumbs to load, or there will be false positives on dialogue mod followups
  if (isNil(breadcrumbs)) {
    return false;
  }

  if (isSubscriptionPath(path, breadcrumbs)) {
    return false;
  }

  return Boolean(breadcrumbs?.find(({ type }) => type === 'dialogues'));
}

export function isSystemDialoguePath(path: string, breadcrumbs: BreadCrumb[] | null): boolean {
  return (
    path.includes('system-dialogue/') ||
    path.includes('system-dialogues/') ||
    Boolean(breadcrumbs?.find(({ type }) => type === 'system'))
  );
}

export function isSpecialDialoguePath(
  folderType: 'greeting' | 'fallback' | string,
  path: string,
  breadcrumbs: BreadCrumb[] | null,
): boolean {
  if (folderType !== 'greeting' && folderType !== 'fallback') {
    return false;
  }

  return path.includes(folderType) || Boolean(breadcrumbs?.find(({ type }) => type === folderType));
}

function isDialogueInBreadcrumbs(dialogueId: string, breadcrumbs: BreadCrumb[] | null): boolean {
  return Boolean(breadcrumbs?.some(({ id }) => id === dialogueId));
}

function isSkillInBreadcrumbs(skillId: string, breadcrumbs: BreadCrumb[] | null): boolean {
  return Boolean(breadcrumbs?.some(({ type, id }) => type === 'skill' && id === skillId));
}

function isTopicInBreadcrumbs(topicId: string, breadcrumbs: BreadCrumb[] | null): boolean {
  return Boolean(breadcrumbs?.some(({ type, id }) => type === 'topic' && id === topicId));
}

export function dialogueInBreadcrumbsChecker(dialogueId: string): BreadCrumbCheck {
  return (_pathname, breadcrumbs) => isDialogueInBreadcrumbs(dialogueId, breadcrumbs);
}

export function skillInBreadcrumbsChecker(skillId: string): BreadCrumbCheck {
  return (_pathname, breadcrumbs) => isSkillInBreadcrumbs(skillId, breadcrumbs);
}

export function topicsInBreadcrumbsChecker(topicId: string): BreadCrumbCheck {
  return (_pathname, breadcrumbs) => isTopicInBreadcrumbs(topicId, breadcrumbs);
}
