import { ChevronDown, ChevronRight } from 'frontend/assets/icons';
import { Icon } from 'frontend/components/Icon/Icon';
import useLocalStorageState from 'frontend/hooks/useLocalStorageState';

import styles from './SystemLabels.scss';

const STORAGE_KEY = 'system-labels-open';

export default function SystemLabels({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useLocalStorageState(STORAGE_KEY, false);

  return (
    <>
      <div className={styles.container}>
        <span className={styles.text}>System Labels</span>
        <button className={styles.button} type="button" onClick={() => setIsOpen(!isOpen)}>
          <Icon component={isOpen ? ChevronDown : ChevronRight} />
        </button>
      </div>
      {isOpen && children}
    </>
  );
}
