import { useMemo } from 'react';

import type { AttachmentType, MessageReferencesType } from 'frontend/api/generated';
import { RenderHTML, TextDiff } from 'frontend/components';
import { SENDER_TYPES, type SenderType } from 'frontend/features/Inbox/constants';
import { useAccessToken } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { CHAT_SOURCE } from 'frontend/types/chat';
import { isFileTypeImage } from 'frontend/utils';

import styles from './ChatMessage.scss';
import KindlyEntityDiff from './KindlyEntityDiff';
import MessageReferences from './MessageReferences/MessageReferences';
import { type DedupedMessageReferences, annotateReferences, dedupeMessageReferences } from './MessageReferences/utils';
import sharedStyles from '../../shared.scss';
import { getAttachementSource } from '../../utils';
import MessageTag from '../MessageTag';

function shouldRenderAsHTML(sender, chatSource) {
  if (sender === SENDER_TYPES.USER && chatSource === CHAT_SOURCE.EMAIL) {
    // FIXME(security): This can only be enabled after email sanitizing is enabled
    // return true;
    return false;
  }
  return [
    SENDER_TYPES.BOT,
    SENDER_TYPES.EXTERNAL,
    SENDER_TYPES.SYSTEM,
    SENDER_TYPES.WEBHOOK,
    SENDER_TYPES.AGENT,
  ].includes(sender);
}

function attachInlineAttachments(message, attachments, token) {
  if (!token || !message) {
    return null;
  }
  return message.split(/(\[image:\s?[^<>:;,?"*|/]+\])/).map((messagePart, index) => {
    const attachmentName = messagePart.match(/^\[image:\s?([^<>:;,?"*|/]+)\]$/)?.[1];
    if (attachmentName) {
      const attachment = attachments.find((att) => att.name === attachmentName);
      if (attachment && isFileTypeImage(attachment.type)) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.attachmentContainer} key={`${attachment.id}-${index}`}>
            <img src={getAttachementSource(attachment, token)} alt={attachment.name} />
          </div>
        );
      }
    }
    // eslint-disable-next-line react/no-array-index-key
    return <p key={`${messagePart}-${index}`}>{messagePart}</p>;
  });
}

interface ChatMessageProps {
  attachments: AttachmentType[];
  botId: string;
  chatSource?: CHAT_SOURCE;
  message: string;
  messageReferences?: Omit<MessageReferencesType, 'Id'>[];
  messageWithKindlyEntities?: string;
  sender: SenderType;
}

export default function ChatMessage({
  message,
  messageReferences,
  attachments,
  sender,
  messageWithKindlyEntities,
  botId,
  chatSource,
}: ChatMessageProps) {
  const token = useAccessToken();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canManageKindlyEntities = hasBotPerms('manage_kindly_entities');

  let dedupedMessageReferences: DedupedMessageReferences[] | undefined;
  if (messageReferences?.length) {
    dedupedMessageReferences = dedupeMessageReferences(messageReferences);
    message = annotateReferences(message, dedupedMessageReferences);
  }

  const messageWithInlineAttachments = useMemo(
    () => attachInlineAttachments(message, attachments, token),
    [message, attachments, token],
  );

  if (!message && attachments.length <= 0 && sender !== SENDER_TYPES.USER) {
    return <MessageTag>WEBHOOK ONLY</MessageTag>;
  }

  if (shouldRenderAsHTML(sender, chatSource)) {
    return (
      <div className={`${sharedStyles.chatMessage} ${sharedStyles.chatMessageFromBot}`}>
        <RenderHTML html={message} />
        {dedupedMessageReferences && <MessageReferences references={dedupedMessageReferences} />}
      </div>
    );
  }

  if (canManageKindlyEntities && messageWithKindlyEntities && messageWithKindlyEntities !== message) {
    return (
      <div className={sharedStyles.chatMessage}>
        <TextDiff originalText={message} updatedText={messageWithKindlyEntities} component={KindlyEntityDiff} />
      </div>
    );
  }

  return <div className={sharedStyles.chatMessage}>{messageWithInlineAttachments}</div>;
}
