import React, { useMemo, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';

import type { FiltersType } from 'frontend/api/generated';
import { Info, Trash } from 'frontend/assets/icons';
import { Button, Checkbox, Icon, Input, Pagination, Table, Tooltip } from 'frontend/components';
import type { Column } from 'frontend/components/Table/Table';
import { FIELD_COLOR } from 'frontend/constants';
import { ModalForm } from 'frontend/features/Modals';
import { required } from 'frontend/form/validators';
import { usePagination } from 'frontend/hooks';

import styles from './UpdateFiltersModal.scss';
import type { FiltersPathType } from '../../Filters';
import useFiltersPermissions from '../../hooks/useFiltersPermissions';
import useUpdateSavedFilters from '../../hooks/useUpdateSavedFilters';

type UpdateFiltersModalProps = {
  hide: (...args: unknown[]) => unknown;
  args: {
    userId: string;
    botId: string;
    filters: FiltersType['filters'];
    type: 'create' | 'update';
    path: FiltersPathType;
    onClick?: (filter: FiltersType, e?: React.SyntheticEvent<HTMLDivElement>) => void;
    onSave?: (filter: FiltersType[]) => void;
  };
};

const FiltersListComponent = ({ rowData }: Column) => {
  const {
    input: { value },
  } = useField(`filters[${rowData.index}].filters`);

  const valueArr = Array.isArray(value) ? value : [value];

  return (
    <div className={styles.filtersList}>
      {valueArr?.map((filter) =>
        Object.entries(filter).map(([key, val]) => (
          <div className={styles.filtersListFilter} key={key}>
            <div className={styles.filtersListFilterHeader}>
              <span>{key}</span>
            </div>
            <div className={styles.filtersListFilters}>{Array.isArray(val) ? val.join(', ') : (val as string)}</div>
          </div>
        )),
      )}
    </div>
  );
};

const FiltersNameComponent = ({ rowData }: Column) => {
  const { canEdit } = useFiltersPermissions({ compareUserId: rowData.user.id });
  return (
    <Field
      component={Input}
      fieldColor={FIELD_COLOR.MISCHKA}
      maxLength={25}
      disabled={!canEdit}
      validate={required}
      placeholder="Name these filters"
      name={`filters[${rowData.index}].name`}
    />
  );
};

const FiltersFavoriteComponent = ({ rowData }: Column) => (
  <Field component={Checkbox} type="checkbox" name={`filters[${rowData.index}].isFavorite`} />
);

const FiltersWorkspaceComponent = ({ rowData }: Column) => {
  const { canEdit } = useFiltersPermissions({ compareUserId: rowData.user.id });
  return (
    <Field component={Checkbox} disabled={!canEdit} type="checkbox" name={`filters[${rowData.index}].isWorkspace`} />
  );
};

const FiltersActionsComponent = ({ rowData }: Column) => {
  const { change } = useForm();
  const {
    input: { value },
  } = useField('filters');

  const { canEdit } = useFiltersPermissions({ compareUserId: rowData.user.id });

  return (
    <div className={styles.actionsContainer}>
      {canEdit && (
        <Icon
          component={Trash}
          hoverColor="warning"
          onClick={() => {
            change(
              `filters`,
              value.map((filter, idx) => {
                if (idx === rowData.index) {
                  return {
                    ...filter,
                    deleted: true,
                  };
                }
                return filter;
              }),
            );
          }}
          width={24}
          height={24}
          color="darkGray"
        />
      )}
      {rowData.onSelect && <Button size="small" text="Apply" onClick={(e) => rowData.onSelect(rowData, e)} />}
    </div>
  );
};

const FiltersOwnedByComponent = ({ rowData }: Column) => {
  const { canEdit } = useFiltersPermissions({ compareUserId: rowData.user.id });
  return <div>{canEdit ? 'You' : rowData.user.fullName}</div>;
};

const columns = [
  { key: 'name', component: FiltersNameComponent },
  { key: 'filters', component: FiltersListComponent },
  {
    key: 'favorite',
    title: (
      <div className={styles.filterHeaderTitle}>
        Favorite
        <Tooltip className={styles.filtersTooltip}>
          <Icon width={18} height={18} component={Info} />
          <Tooltip.Body>Mark as favorite, so you can access it from the main filters dropdown.</Tooltip.Body>
        </Tooltip>
      </div>
    ),
    component: FiltersFavoriteComponent,
  },
  {
    key: 'workspace',
    title: (
      <div className={styles.filterHeaderTitle}>
        Workspace
        <Tooltip className={styles.filtersTooltip}>
          <Icon width={18} height={18} component={Info} />
          <Tooltip.Body>Marked as workspace can be accessed by all users within that workspace.</Tooltip.Body>
        </Tooltip>
      </div>
    ),
    component: FiltersWorkspaceComponent,
  },
  { key: 'user.name', title: 'Owned by', component: FiltersOwnedByComponent },
  { key: 'actions', component: FiltersActionsComponent },
];

const SavedFiltersTable = ({
  onClick,
}: {
  onClick?: (filter: FiltersType, e?: React.SyntheticEvent<HTMLOrSVGElement>) => void;
}) => {
  const {
    input: { value: filters },
  } = useField('filters');

  const [filtersWithClick, setFiltersWithClick] = useState<FiltersType['filters']>(null);

  const filterFilters = useMemo(
    () =>
      (filtersWithClick || filters)
        .map((filter) => ({
          ...filter,
          ...(onClick && { onSelect: onClick }),
          deleted:
            filters.find(({ id }) => filtersWithClick?.find(({ id: id2 }) => id === id2))?.deleted ||
            filters.find(({ id }) => id === filter.id)?.deleted,
        }))
        .filter(({ deleted }) => !deleted),
    [filters, filtersWithClick, onClick],
  );

  const [currentData, page, pages, setPage] = usePagination({
    objects: filterFilters,
    pageSize: 10,
  });

  return (
    <>
      <Input
        className={styles.filtersSearch}
        placeholder="Search filters"
        input={{
          type: 'text',
          name: 'saved-filters-search',
          onChange: (e) => {
            if (e.target.value) {
              setFiltersWithClick(filterFilters?.filter((filter) => filter.name.includes(e.target.value)));
            } else {
              setFiltersWithClick(null);
            }
          },
        }}
      />
      <Table className={styles.filtersTable} data={currentData} columns={columns as Column[]} />
      <div>
        <Pagination currentPage={page} pages={pages} setPage={setPage} />
      </div>
    </>
  );
};

function UpdateFiltersModal({
  hide,
  args: { botId, userId, filters, type, path, onClick, onSave },
}: UpdateFiltersModalProps) {
  const onSubmit = useUpdateSavedFilters({ userId, botId, filters, type, path });

  const handleOnClick = (val: FiltersType, e?: React.SyntheticEvent<HTMLOrSVGElement>) => {
    // In this case the div is always something that should call the onClick callback
    if ((e?.target as HTMLElement).tagName?.toLowerCase() === 'svg') {
      onClick?.(val);
      hide();
    }
  };

  const handleSave = (val: FiltersType) => {
    onSubmit(val, undefined, (data) => {
      onSave?.(data);
      hide();
    });
  };

  return (
    <ModalForm
      showFormErrors={false}
      title={type === 'create' ? 'Create filters' : 'Update filters'}
      className={type === 'update' ? styles.filtersModalBody : undefined}
      onOkText="Save"
      onSubmit={handleSave}
      initialValues={
        Array.isArray(filters) ? { filters: filters.map((filter, index) => ({ ...filter, index })) } : { filters }
      }
      hide={hide}
    >
      {type === 'create' ? (
        <div className={styles.newFiltersWrapper}>
          <div>
            <Field
              validate={required}
              component={Input}
              label="Filters name"
              maxLength={25}
              placeholder="Name these filters"
              name="name"
            />
          </div>
          <div>
            <Field
              component={Checkbox}
              type="checkbox"
              label={
                <div className={styles.checkboxLabel}>
                  Add to favorites&nbsp;
                  <Tooltip className={styles.iconTooltip}>
                    <Icon component={Info} width={18} height={18} />
                    <Tooltip.Body>
                      This adds the filter to your favorites, so its easy accessible through the Add filter dropdown.
                    </Tooltip.Body>
                  </Tooltip>
                </div>
              }
              name="isFavorite"
            />
            <Field
              component={Checkbox}
              type="checkbox"
              label={
                <div className={styles.checkboxLabel}>
                  Add to workspace&nbsp;
                  <Tooltip className={styles.iconTooltip}>
                    <Icon component={Info} width={18} height={18} />
                    <Tooltip.Body>
                      This adds the filter to the workspace, so all users within the workspace can access it.
                    </Tooltip.Body>
                  </Tooltip>
                </div>
              }
              name="isWorkspace"
            />
          </div>
        </div>
      ) : (
        <SavedFiltersTable onClick={handleOnClick} />
      )}
    </ModalForm>
  );
}

export default UpdateFiltersModal;
